//CardQuiz.tsx

import React from "react";
import Title from "./Title";

type CardQuizProps = {
  questionText: string;
  answers: { text: string | React.ReactNode; onClick?: () => void }[];
  animateClass: string;
  changeStep?: (nextStep: number) => void;
  setPricingStep?: (nextStep: number) => void;
};

const CardQuiz: React.FC<CardQuizProps> = ({
  questionText,
  answers,
  animateClass,
  changeStep,
  setPricingStep,
}) => {
  return (
    <div className="fixed min-h-screen inset-0 bg-black bg-opacity-60  overflow-auto z-50 ">
      <div className="flex justify-center items-center min-h-full">
        <div
          className={` ${animateClass} !bg-primary !p-12 rounded-lg shadow-xl border border-yellow-500 w-[500px] max-w-full`}
        >
          {/* Close Icon */}
          {changeStep && (
            <button
              onClick={() => {
                changeStep(1);
                if (setPricingStep) setPricingStep(0);
              }}
              className="absolute top-4 right-4 text-white hover:text-yellow-500 transition"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}

          <div className="">
             {/* Add Title Component Here */}
             <div className="mb-6 text-center">
              <Title height="40px" width="auto" /> {/* Customize size */}
            </div>
            <p className="question-text text-white text-xl font-bold mb-6">
              {questionText}
            </p>
            <div className=" space-y-4">
              {answers.map((answer, index) => (
                <div key={index} className="answer-wrapper">
                  {typeof answer.text !== "string" ? (
                    <div className="input-wrapper">{answer.text}</div>
                  ) : (
                    <button
                      key={index}
                      onClick={answer.onClick}
                      className="primary-btn"
                    >
                      {answer.text}
                    </button>
                  )}
                </div>
              ))}
            </div>
            {/* Note at the bottom */}
            <p className="mt-6 text-xs text-gray-400 text-center italic">
            <strong>Note:</strong> LLEAMI can make mistakes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardQuiz;
