//CookiesConsentBanner.tsx

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: Gtag;
    fbq: Fbq;
  }
}

type Gtag = (...args: any[]) => void;

interface Fbq {
  (...args: any[]): void;
  queue: any[];
  callMethod: (...args: any[]) => void;
  loaded: boolean;
  version: string;
}

const CookieConsentBanner: React.FC = () => {
  const [isBannerVisible, setBannerVisible] = useState(false);
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (!consent) {
      setBannerVisible(true);
    } else {
      const savedPreferences = JSON.parse(consent);
      if (savedPreferences.analytics) loadAnalyticsScripts();
      if (savedPreferences.marketing) loadFacebookPixel();
    }
  }, []);

  const savePreferences = () => {
    Cookies.set('cookieConsent', JSON.stringify(cookiePreferences), {
      expires: 365,
      path: '/',
    });
    setBannerVisible(false);
    setPreferencesVisible(false);

    if (cookiePreferences.analytics) loadAnalyticsScripts();
    if (cookiePreferences.marketing) loadFacebookPixel();
  };

  const handleAcceptAll = () => {
    setCookiePreferences({
      necessary: true,
      analytics: true,
      marketing: true,
    });
    savePreferences();
  };

  const handleDeclineAll = () => {
    setCookiePreferences({
      necessary: true,
      analytics: false,
      marketing: false,
    });
    savePreferences();
  };

  const loadAnalyticsScripts = () => {
    if (typeof window === 'undefined') return;

    if (!window.gtag) {
      // Load the Google Analytics script
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-T4EW41JL4H`; // Your Tracking ID
      script.async = true;
      document.head.appendChild(script);

      // Initialize gtag function
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: any[]) {
        window.dataLayer.push(args);
      }
      window.gtag = gtag;

      // Initialize Google Analytics
      window.gtag('js', new Date());
      window.gtag('config', 'G-T4EW41JL4H'); // Your Tracking ID
    }
  };

  const loadFacebookPixel = () => {
    if (typeof window === 'undefined') return;

    if (!window.fbq) {
      // Define fbq function and assign to window.fbq
      window.fbq = function (...args: any[]) {
        window.fbq.callMethod
          ? window.fbq.callMethod.apply(window.fbq, args)
          : window.fbq.queue.push(args);
      } as Fbq;

      // Assign properties to fbq
      window.fbq.queue = [];
      window.fbq.loaded = true;
      window.fbq.version = '2.0';
      window.fbq.callMethod = function (...args: any[]) {
        window.fbq.queue.push(args);
      };

      // Load the Facebook Pixel script
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://connect.facebook.net/en_US/fbevents.js';
      document.head.appendChild(script);

      // Initialize the Pixel with your ID
      window.fbq('init', '896332909287912'); // Your Pixel ID
      window.fbq('track', 'PageView');
    }
  };

  if (!isBannerVisible) return null;

  return (
    <div className="cookie-banner fixed bottom-0 w-full bg-[#06060e] text-white p-4 z-50 overflow-y-auto max-h-screen">
      {!preferencesVisible && !privacyPolicyVisible ? (
        <div className="flex flex-col md:flex-row items-center justify-between max-w-4xl mx-auto">
          <p className="mb-4 md:mb-0 text-center md:text-left">
            We use cookies to improve your experience.{' '}
            <button
              className="underline text-secondary"
              onClick={() => setPrivacyPolicyVisible(true)}
            >
              Learn more
            </button>
          </p>
          <div className="flex flex-col md:flex-row gap-2">
            <button className="primary-btn" onClick={handleAcceptAll}>
              Accept All
            </button>
            <button className="primary-btn" onClick={handleDeclineAll}>
              Decline All
            </button>
            <button
              className="primary-btn"
              onClick={() => setPreferencesVisible(true)}
            >
              Customize Preferences
            </button>
          </div>
        </div>
      ) : preferencesVisible ? (
        <div className="flex flex-col items-center max-w-4xl mx-auto">
          <h3 className="text-lg font-semibold mb-4">Manage Cookie Preferences</h3>
          <div className="flex flex-col gap-2 mb-4 w-full max-w-md">
            <label className="flex items-center">
              <input type="checkbox" checked={true} disabled className="mr-2" />
              Necessary (always enabled)
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={cookiePreferences.analytics}
                onChange={(e) =>
                  setCookiePreferences((prev) => ({
                    ...prev,
                    analytics: e.target.checked,
                  }))
                }
                className="mr-2"
              />
              Analytics
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={cookiePreferences.marketing}
                onChange={(e) =>
                  setCookiePreferences((prev) => ({
                    ...prev,
                    marketing: e.target.checked,
                  }))
                }
                className="mr-2"
              />
              Marketing
            </label>
          </div>
          <button className="primary-btn w-full max-w-md" onClick={savePreferences}>
            Save Preferences
          </button>
        </div>
      ) : (
        // Privacy Policy View
        <div className="privacy-policy-content max-w-4xl mx-auto">
          <h3 className="text-lg font-semibold mb-4">Privacy Policy</h3>
          <div className="privacy-policy-text overflow-y-auto max-h-96 p-4 bg-gray-800 rounded-md text-sm leading-relaxed">
            {/* Privacy Policy Content */}
            <p className="font-bold">Last updated: November 28, 2024</p>
            <p>
              This Privacy Policy for <strong>LLEAMI</strong> ("<strong>we</strong>", "<strong>us</strong>", or "<strong>our</strong>") describes how we collect, use, and share your personal information when you use our services ("<strong>Services</strong>"), including when you:
            </p>
            <ul className="list-disc ml-5 mb-4">
              <li>
                Visit our website at{' '}
                <a href="http://www.lleami.com" className="underline text-secondary">
                  http://www.lleami.com
                </a>{' '}
                or any website of ours that links to this Privacy Policy.
              </li>
              <li>
                Use <strong>LLEAMI</strong>, an AI-powered platform designed to help users test ideas by creating structured team startup plans. It generates a draft business model, team canvas, and validation strategies tailored to your concept and prototype language. LLEAMI serves as a starting point to refine your ideas and guide your next steps in the entrepreneurial journey.
              </li>
              <li>
                Engage with us in other related ways, including sales, marketing, or events.
              </li>
            </ul>
            <p>
              If you have any questions or concerns about this Privacy Policy, please contact us at <strong>info@lleami.com</strong>.
            </p>

            {/* Privacy Policy Sections */}
            <h4 className="font-semibold mt-6 mb-2">1. Information We Collect</h4>
            <h5 className="font-semibold mt-4 mb-2">Personal Information You Disclose to Us</h5>
            <p>
              We collect personal information that you voluntarily provide to us when you express interest in obtaining information about us or our products and Services, participate in activities on the Services, or contact us directly.
            </p>
            <p className="mt-2">
              <strong>Personal Information Collected:</strong>
            </p>
            <ul className="list-disc ml-5">
              <li>Names</li>
              <li>Email addresses</li>
              <li>Job titles</li>
            </ul>
            <p className="mt-2">
              <strong>Sensitive Information:</strong> We do not process sensitive personal information such as racial or ethnic origin, sexual orientation, or religious beliefs.
            </p>
            <p className="mt-2">
              <strong>Payment Data:</strong> If you make purchases, we may collect data necessary to process your payment, such as your payment instrument number and security code. All payment data is handled and stored by <strong>Stripe</strong>. You may find their privacy policy{' '}
              <a href="https://stripe.com/privacy" className="underline text-secondary">
                here
              </a>
              .
            </p>
            <p className="mt-2">
              All personal information you provide must be true, complete, and accurate, and you must notify us of any changes.
            </p>

            <h5 className="font-semibold mt-4 mb-2">Information Automatically Collected</h5>
            <p>
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity but may include:
            </p>
            <ul className="list-disc ml-5">
              <li>
                <strong>Log and Usage Data:</strong> IP address, browser type, operating system, pages viewed, and other usage details.
              </li>
              <li>
                <strong>Device Data:</strong> Information about your computer or mobile device.
              </li>
              <li>
                <strong>Location Data:</strong> Information about your device's location based on your IP address.
              </li>
            </ul>
            <p className="mt-2">
              <strong>Cookies and Similar Technologies:</strong> We collect information through cookies and similar tracking technologies to enhance your experience on our Services.
            </p>

            <h4 className="font-semibold mt-6 mb-2">2. How We Use Your Information</h4>
            <p>
              We process your personal information to:
            </p>
            <ul className="list-disc ml-5">
              <li>Provide, improve, and administer our Services.</li>
              <li>Communicate with you.</li>
              <li>For security and fraud prevention.</li>
              <li>Comply with legal obligations.</li>
              <li>Save or protect an individual's vital interest.</li>
            </ul>

            <h4 className="font-semibold mt-6 mb-2">3. Legal Bases for Processing</h4>
            <p>
              We process your personal information based on:
            </p>
            <ul className="list-disc ml-5">
              <li>
                <strong>Consent:</strong> You have given us permission to use your personal information for a specific purpose. You can withdraw your consent at any time by contacting us at <strong>info@lleami.com</strong>.
              </li>
              <li>
                <strong>Legal Obligations:</strong> Compliance with legal obligations.
              </li>
              <li>
                <strong>Vital Interests:</strong> To protect your vital interests or those of another person.
              </li>
            </ul>

            <h4 className="font-semibold mt-6 mb-2">4. Sharing Your Personal Information</h4>
            <p>
              We may share your personal information in specific situations:
            </p>
            <ul className="list-disc ml-5">
              <li>
                <strong>Service Providers:</strong> With third-party vendors, service providers, contractors, or agents who perform services on our behalf.
              </li>
              <li>
                <strong>Business Transfers:</strong> In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business.
              </li>
            </ul>
            <p className="mt-2">
              <strong>Categories of Third Parties:</strong>
            </p>
            <ul className="list-disc ml-5">
              <li>Ad Networks</li>
              <li>Social Networks</li>
            </ul>

            <h4 className="font-semibold mt-6 mb-2">5. Cookies and Tracking Technologies</h4>
            <p>
              We may use cookies and similar tracking technologies to collect and store your information. This includes:
            </p>
            <ul className="list-disc ml-5">
              <li>
                <strong>Necessary Cookies:</strong> Enable essential website functionality.
              </li>
              <li>
                <strong>Analytics Cookies:</strong> Collect information about how visitors use our website (e.g., Google Analytics).
              </li>
              <li>
                <strong>Marketing Cookies:</strong> Track user behavior to provide relevant advertising.
              </li>
            </ul>
            <p className="mt-2">
              <strong>Google Analytics:</strong> We use Google Analytics to track and analyze the use of our Services. To opt out, visit the{' '}
              <a href="https://tools.google.com/dlpage/gaoptout/" className="underline text-secondary">
                Google Analytics Opt-out Browser Add-on
              </a>
              .
            </p>

            <h4 className="font-semibold mt-6 mb-2">6. Artificial Intelligence Products</h4>
            <p>
              As part of our Services, we offer products powered by artificial intelligence (AI), including:
            </p>
            <ul className="list-disc ml-5">
              <li>AI Applications</li>
              <li>AI Bots</li>
              <li>Text Analysis</li>
            </ul>
            <p className="mt-2">
              <strong>Use of AI Technologies:</strong> We provide AI products through third-party service providers, including <strong>OpenAI</strong>. Your input and personal information will be shared with these providers to enable the use of our AI products. You must not use the AI products in any way that violates the terms or policies of any AI service provider.
            </p>

            <h4 className="font-semibold mt-6 mb-2">7. Data Retention</h4>
            <p>
              We keep your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law. No purpose in this policy will require us to keep your personal information longer than <strong>2 years</strong>.
            </p>
            <p className="mt-2">
              When we have no ongoing legitimate business need to process your personal information, we will delete or anonymize it.
            </p>

            <h4 className="font-semibold mt-6 mb-2">8. Children's Privacy</h4>
            <p>
              We do not knowingly collect data from or market to children under <strong>18 years</strong> of age. By using the Services, you represent that you are at least 18. If you become aware of any data we may have collected from children under 18, please contact us at <strong>info@lleami.com</strong>.
            </p>

            <h4 className="font-semibold mt-6 mb-2">9. Your Privacy Rights</h4>
            <p>
              Depending on your location, you may have certain rights under applicable data protection laws, including:
            </p>
            <ul className="list-disc ml-5">
              <li>
                <strong>Access:</strong> Request access to your personal information.
              </li>
              <li>
                <strong>Correction:</strong> Request correction of inaccurate personal information.
              </li>
              <li>
                <strong>Deletion:</strong> Request deletion of your personal information.
              </li>
              <li>
                <strong>Restriction:</strong> Request restriction of processing your personal information.
              </li>
              <li>
                <strong>Objection:</strong> Object to processing your personal information.
              </li>
              <li>
                <strong>Data Portability:</strong> Request transfer of your personal information.
              </li>
            </ul>
            <p className="mt-2">
              To exercise these rights, please contact us at <strong>info@lleami.com</strong>.
            </p>
            <p className="mt-2">
              <strong>Withdrawing Consent:</strong> You have the right to withdraw your consent at any time. To withdraw your consent, contact us at <strong>info@lleami.com</strong>.
            </p>
            <p className="mt-2">
              <strong>Cookies and Similar Technologies:</strong> You can set your browser to refuse cookies or alert you when cookies are being sent.
            </p>

            <h4 className="font-semibold mt-6 mb-2">10. Do-Not-Track Features</h4>
            <p>
              We do not currently respond to Do-Not-Track (DNT) browser signals. If a standard for online tracking is adopted, we will update this Privacy Policy accordingly.
            </p>

            <h4 className="font-semibold mt-6 mb-2">11. Updates to This Policy</h4>
            <p>
              We may update this Privacy Policy from time to time. The updated version will be indicated by the "Last updated" date at the top of this policy. We encourage you to review this Privacy Policy frequently.
            </p>

            <h4 className="font-semibold mt-6 mb-2">12. Contact Us</h4>
            <p>
              If you have any questions or comments about this Privacy Policy, please contact us at:
            </p>
            <p>
              <strong>Email:</strong> info@lleami.com
            </p>
            <p>
              <strong>Address:</strong> [Your Company Address]
            </p>

            <h4 className="font-semibold mt-6 mb-2">13. Reviewing, Updating, or Deleting Your Data</h4>
            <p>
              To review, update, or delete your personal information, please contact us at <strong>info@lleami.com</strong>. We will respond to your request in accordance with applicable data protection laws.
            </p>

            <p className="mt-4">
              <em>
                Note: This Privacy Policy is designed to provide a clear and comprehensive explanation of how <strong>LLEAMI</strong> handles your personal information. Please read it carefully to understand our practices regarding your personal data.
              </em>
            </p>
          </div>
          <button
            className="primary-btn mt-4"
            onClick={() => setPrivacyPolicyVisible(false)}
          >
            Back to Consent Options
          </button>
        </div>
      )}
    </div>
  );
};

export default CookieConsentBanner;
