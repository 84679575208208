//Hero.tsx

import React, { FC, useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import InputArea from "./InputArea";
import Loading from "./loading";
import remarkGfm from "remark-gfm";
import { Plan } from "./Common";


type UserPreferences = {
  email: string;
  language: string;
  region: string;
  agreement: boolean;
  plan: Plan | null;
};


type THeroProps = {
  promptValue: string;
  setPromptValue: React.Dispatch<React.SetStateAction<string>>;
  handleIdeaSubmission: () => Promise<void>;
  handleDisplayResult: () => void;
  isLoading?: boolean;
  placeholder?: string;
  currentTask?: string;
  markdownMessages?: string[];
  isMarkdownVisible: boolean;
  userPreferences: UserPreferences;
};


const Hero: FC<THeroProps> = ({
  promptValue,
  setPromptValue,
  handleIdeaSubmission,
  handleDisplayResult,
  isLoading = false,
  placeholder,
  currentTask,
  markdownMessages = [],
  isMarkdownVisible = false,
  userPreferences,


}) => {
  const { language } = userPreferences; 
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [displayedMessages, setDisplayedMessages] = useState<string[]>([]);
  const [isAtBottom, setIsAtBottom] = useState(true);


  // Handle new messages
  useEffect(() => {
    const newMessages = markdownMessages.filter(
      (message) => !displayedMessages.includes(message)
    );
    if (newMessages.length > 0) {
      setDisplayedMessages((prevMessages) => [...prevMessages, ...newMessages]);
    }
  }, [markdownMessages, displayedMessages]);


  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (!container) return;
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom =
        Math.abs(scrollTop + clientHeight - scrollHeight) <= 10;
      setIsAtBottom(isAtBottom);
    };
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [scrollableContainerRef.current]);
  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (isAtBottom && container) {
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
  }, [displayedMessages]);

  // Ensure markdown content is visible and updated
  useEffect(() => {
    if (isMarkdownVisible) {
      const container = scrollableContainerRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight; // Ensure we scroll to bottom on new messages
      }
    }
  }, [isMarkdownVisible, displayedMessages]);

  const [displayedText, setDisplayedText] = useState("");
  // Typing Animation for Final Message
  useEffect(() => {
    let index = -1;
    setDisplayedText(""); // Reset displayed text when currentTask changes

    if (currentTask) {
      const typingSpeed = 200; // Adjust typing speed (milliseconds per character)

      const typeInterval = setInterval(() => {
        setDisplayedText((prev) => prev + currentTask.charAt(index));
        index++;
        if (index >= currentTask.length) {
          clearInterval(typeInterval);
        }
      }, typingSpeed);

      return () => clearInterval(typeInterval); // Cleanup on unmount or when currentTask changes
    }
  }, [currentTask]);






  return (
    <div className="container w-full">
      {/* Note above the InputArea */}
      <p className="mt-1 text-lg text-white text-left">
        Enter idea in: <strong>{language}</strong>
      </p>
      {/* Input area */}
      <div className="w-full">
        <InputArea
          promptValue={promptValue}
          setPromptValue={setPromptValue}
          handleIdeaSubmission={handleIdeaSubmission}
          handleDisplayResult={handleDisplayResult}
          placeholder={placeholder}
          disabled={isLoading}
        />
      </div>

      {/* Note below the InputArea */}
      <p className="mt-1 text-xs text-white text-center italic">
              <strong>Note:</strong> LLEAMI can make mistakes.
            </p>

      {/* Loading Indicator */}
      {isLoading && (
        <div className="py-4">
          <div className="relative">
            <p className="text-[18px] font-medium text-white">{currentTask}</p>
          </div>
          <div className="mt-4">
            <Loading />
          </div>
        </div>
      )}



      {/* Final Message with Typing Animation */}
      {!isLoading && currentTask && (
        <div className="final-message py-4">
          <p className="text-[18px] font-medium text-white flex items-center">
            {displayedText}
            {/* Optional Cursor */}
            <span className="blinking-cursor">|</span>
          </p>
        </div>
      )}

      {/* Markdown Output */}
      {isMarkdownVisible && (
        <div
          ref={scrollableContainerRef}
          className="markdown-document overflow-auto h-[300px] max-h-[300px] border border-gray-700 rounded-md p-4 bg-black my-4"
        >
          {displayedMessages.map((message, index) => (
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                table: ({ node, ...props }) => (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      border: "1px solid #ccc",
                      overflow: "auto",
                      marginTop: "20px",
                    }}
                    {...props}
                  />
                ),
                th: ({ node, ...props }) => (
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      backgroundColor: "#f4f4f4",
                      fontWeight: "bold",
                      color: "black",
                    }}
                    {...props}
                  />
                ),
                td: ({ node, ...props }) => (
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                    }}
                    {...props}
                  />
                ),
              }}
              key={index}
              className="prose !text-white"
            >
              {message}
            </ReactMarkdown>
          ))}
        </div>
      )}
    </div>
  );
};

export default Hero;
