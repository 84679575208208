//GradientAnimatingBorder.tsx

import React from "react";

export default function GradientAnimatingBorder() {
  return (
    <div className="absolute inset-0 border-t-1 border-transparent w-[80%] mx-auto">
      <div className="absolute inset-0 h-[1px] bg-gradient-to-r from-black via-secondary to-black   ">
        <div className="absolute left-0 -top-0 w-6 h-[2px] rounded-full bg-yellow-500  animate-moveDot shadow-[0_2px_12px_2px_rgba(246,203,14,0.6)]" />
      </div>
    </div>
  );
}
