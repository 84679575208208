//Footer.tsx

import GradientAnimatingBorder from "./GradientAnimatingBorder";
import Title from "./Title";
import { useState } from "react";
import EmailContact from "./EmailContact";


type FooterProps = {
  apiBaseUrl: string;
};

const Footer: React.FC<FooterProps> = ({ apiBaseUrl }) => {
  const [email, setEmail] = useState<string>(""); // State for email input
  const [message, setMessage] = useState<string | null>(null); // State for feedback message
  const [error, setError] = useState<boolean>(false); // State for error feedback
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false); // State to control modal visibility

  // Function to validate email
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  // Function to handle form submission
  const handleSubscribe = async () => {
    setMessage(null); // Reset message
    setError(false); // Reset error state

    if (!isValidEmail(email)) {
      setMessage("Please enter a valid email address.");
      setError(true);
      return;
    }

    try {
      const response = await fetch(`${apiBaseUrl}/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Send email in request body
      });

      const responseData = await response.json();

      if (response.ok) {
        setMessage(responseData.message); // Show appropriate message
       // setMessage("Successfully subscribed!");
        setEmail(""); // Clear input field
      } else {
        setMessage(responseData.message || "Something went wrong. Please try again.");
        setError(true);
        //const errorData = await response.json();
        //setMessage(errorData.message || "Something went wrong. Please try again.");
        ///setError(true);
      }
    } catch (err) {
      console.error(err);
      setMessage("Failed to subscribe. Please check your network connection.");
      setError(true);
    }
  };

  return (
    <div className="relative">
      <footer className="bg-[#06060e]">
        <div className="relative px-10 py-5 mx-auto">
          <GradientAnimatingBorder />

          {/* Main Footer Content */}
          <div className="relative z-10 flex flex-col lg:flex-row justify-between items-start">

            {/* Subscribe Section */}
            <div className="w-full lg:w-1/3">

              <h1 className="max-w-lg text-xl font-semibold tracking-tight text-white">
                Stay in the loop!
              </h1>
              {/* <p className=" !text-gray-300 text-[16px] mt-0">
                We promise, no spam – only value!
              </p> */}
              <div className="flex flex-col mx-auto mt-2.5 space-y-3 md:space-y-0 md:flex-row gap-4">
                <input
                    id="email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update email state
                    className={`input flex-1 w-full md:max-w-lg md:w-auto ${error ? "border-red-500" : ""}`}
                    placeholder="Email Address"
                />
                    
                <button 
                  className="primary-btn !w-auto" 
                  onClick={handleSubscribe}
                >
                  Subscribe
                </button>
              </div>

              {/* Feedback message */}
              {message && (
                <p
                  className={`mt-2 text-sm ${
                    error ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {message}
                </p>
              )}
            </div>

            {/* Industries and Contact Us Wrapper */}
            <div className="w-full flex flex-col items-center text-center mt-6 lg:w-1/3 lg:items-end lg:text-right lg:mt-0">
              
              {/* Industries
              <div className="flex flex-col items-start lg:w-auto lg:mr-12">
                <p className="text-xl font-semibold tracking-tight text-white">
                  Industries
                </p>
                <div className="flex flex-col items-start mt-3 space-y-3">
                  <a
                    href="https://theleanstartup.com"
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                      https://theleanstartup.com
                  </a>
                  <a
                    href="#"
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                </div>
              </div> */}

              {/* Title, Contact Us, Icons */}
              <div className="w-full flex flex-col items-center text-center mt-6 lg:w-1/3 lg:items-end lg:text-right lg:mt-0">
                {/* LLEAMI Title */}
                <div className="mb-2.5 text-center">
                <Title height="32px" width="auto" />
                 <div style={{ height: "0px" }}></div> {/* Adds empty space below the title */}
                </div>

                {/* Contact Us Button */}
                <div className="mb-3 flex justify-center">
                  <button
                    className="primary-btn !w-auto"  // Set button width and ensure auto height
                    onClick={() => setIsContactModalOpen(true)}
                  >
                    Contact Us
                  </button>
                </div>

                {/* Social Media Icons */}
                <div className="flex items-center space-x-1 justify-center lg:justify-end">
                  {/* Instagram
                  <a
                    href="https://instagram.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    { SVG Icon}
                    <svg
                      className="w-10 h-10 fill-current"
                      viewBox="0 -3 30 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.34 3.608 1.314.974.975 1.252 2.242 1.314 3.608.058 1.267.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.34 2.633-1.314 3.608-.975.974-2.242 1.252-3.608 1.314-1.267.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.34-3.608-1.314-.974-.975-1.252-2.242-1.314-3.608-.058-1.267-.07-1.646-.07-4.85s.012-3.584.07-4.85c.062-1.366.34-2.633 1.314-3.608.975-.974 2.242-1.252 3.608-1.314C8.416 2.175 8.796 2.163 12 2.163m0-2.163C8.735 0 8.332.014 7.052.072 5.773.131 4.675.336 3.678 1.334 2.68 2.332 2.476 3.43 2.417 4.71.014 8.332 0 8.735 0 12s.014 3.668.072 4.948c.06 1.28.265 2.378 1.263 3.376.998.997 2.096 1.203 3.376 1.263 1.28.058 1.683.072 4.948.072s3.668-.014 4.948-.072c1.28-.06 2.378-.266 3.376-1.263.997-.998 1.203-2.096 1.263-3.376.058-1.28.072-1.683.072-4.948s-.014-3.668-.072-4.948c-.06-1.28-.266-2.378-1.263-3.376-.998-.997-2.096-1.203-3.376-1.263-1.28-.058-1.683-.072-4.948-.072zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zm0 10.151a3.989 3.989 0 110-7.977 3.989 3.989 0 010 7.977zm6.406-11.845a1.44 1.44 0 110-2.881 1.44 1.44 0 010 2.881z" />
                    </svg>
                  </a> */}
                  {/* Facebook */}
                  <a
                    href="https://www.facebook.com/profile.php?id=61569313723515"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* SVG Icon */}
                    <svg
                      className="w-16 h-10 fill-current"
                      viewBox="0 -3 30 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22.675 0H1.325C.594 0 0 .594 0 1.325v21.351C0 23.406.594 24 1.325 24H12.82V14.708h-3.148v-3.62h3.148V8.413c0-3.11 1.897-4.805 4.669-4.805 1.325 0 2.463.099 2.794.143v3.24h-1.918c-1.504 0-1.796.715-1.796 1.763v2.311h3.588l-.467 3.62h-3.12V24h6.116C23.406 24 24 23.406 24 22.675V1.325C24 .594 23.406 0 22.675 0z" />
                    </svg>
                  </a>
                  {/* X */}
                  <a
                    href="https://x.com/LLEAMI_"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* SVG Icon */}
                    <svg
                      className="w-10 h-10 fill-current"
                      viewBox="0 -3 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.0748 15L6.45272 9.60689L1.91833 15H0L5.60164 8.33938L0 0H4.9252L8.33897 5.08297L12.6163 0H14.5346L9.19291 6.35215L15 15H10.0748ZM12.2111 13.4796H10.9196L2.74677 1.52045H4.03844L7.31172 6.30897L7.87775 7.13991L12.2111 13.4796Z" />
                    </svg>
                  </a>
                  {/* Github */}
                  <a
                    href="https://github.com/AAzzam91"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* SVG Icon */}
                    <svg
                      className="w-10 h-10 fill-current"
                      viewBox="0 2 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      >
                      <path d="M12.026 2C7.13295 1.99937 2.96183 5.54799 2.17842 10.3779C1.395 15.2079 4.23061 19.893 8.87302 21.439C9.37302 21.529 9.55202 21.222 9.55202 20.958C9.55202 20.721 9.54402 20.093 9.54102 19.258C6.76602 19.858 6.18002 17.92 6.18002 17.92C5.99733 17.317 5.60459 16.7993 5.07302 16.461C4.17302 15.842 5.14202 15.856 5.14202 15.856C5.78269 15.9438 6.34657 16.3235 6.66902 16.884C6.94195 17.3803 7.40177 17.747 7.94632 17.9026C8.49087 18.0583 9.07503 17.99 9.56902 17.713C9.61544 17.207 9.84055 16.7341 10.204 16.379C7.99002 16.128 5.66202 15.272 5.66202 11.449C5.64973 10.4602 6.01691 9.5043 6.68802 8.778C6.38437 7.91731 6.42013 6.97325 6.78802 6.138C6.78802 6.138 7.62502 5.869 9.53002 7.159C11.1639 6.71101 12.8882 6.71101 14.522 7.159C16.428 5.868 17.264 6.138 17.264 6.138C17.6336 6.97286 17.6694 7.91757 17.364 8.778C18.0376 9.50423 18.4045 10.4626 18.388 11.453C18.388 15.286 16.058 16.128 13.836 16.375C14.3153 16.8651 14.5612 17.5373 14.511 18.221C14.511 19.555 14.499 20.631 14.499 20.958C14.499 21.225 14.677 21.535 15.186 21.437C19.8265 19.8884 22.6591 15.203 21.874 10.3743C21.089 5.54565 16.9181 1.99888 12.026 2Z"></path>
                    </svg>
                  </a>
                </div>
              </div>

            </div>
          </div>

          {/* Contact Modal */}
          {isContactModalOpen && (
            <EmailContact
              onClose={() => setIsContactModalOpen(false)}
              apiBaseUrl={apiBaseUrl}
            />
          )}
        </div>
      </footer>
    </div>
  );
};

export default Footer;



              {/* Contact Us Button }
              <div className="flex flex-col justify-center">
                  <button
                    className="primary-btn !w-auto"
                    onClick={() => setIsContactModalOpen(true)}
                  >
                    Contact Us
                  </button>
              </div>
{/* 
            <div>
              <p className="font-semibold text-white">Quick Links</p>
              <div className="flex flex-col items-start mt-5 space-y-2">
              <a href="https://x.com" className="link" target="_blank" rel="noopener noreferrer">
                https://x.com
                </a>
                <a href="https://www.facebook.com" className="link" target="_blank" rel="noopener noreferrer">
                https://www.facebook.com
                </a>
                <a href="https://www.instagram.com" className="link" target="_blank" rel="noopener noreferrer">
                https://www.instagram.com
                </a>
              </div>
            </div>}


            {/* <div>
              <p className="font-semibold text-white">Industries</p>
              <div className="flex flex-col items-start mt-5 space-y-2">
                <a href="https://theleanstartup.com" className="link" target="_blank" rel="noopener noreferrer">
                https://theleanstartup.com
                </a>
                 <a href="#" className="link" target="_blank" rel="noopener noreferrer">
                  Link
                </a>
                <a href="#" className="link" target="_blank" rel="noopener noreferrer">
                  Link
                </a>
              </div>
            </div>}
          </div>

          {/* <hr className="my-6 border-secondary border-opacity-30 md:my-8 " />}

          {/* <div className="flex items-center justify-between">
            <Title />
            <div className="flex items-center px-2">
            {/* Instagram }
            <a
              href="https://instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="w-10 h-10 fill-current"
                viewBox="0 0 30 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.34 3.608 1.314.974.975 1.252 2.242 1.314 3.608.058 1.267.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.34 2.633-1.314 3.608-.975.974-2.242 1.252-3.608 1.314-1.267.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.34-3.608-1.314-.974-.975-1.252-2.242-1.314-3.608-.058-1.267-.07-1.646-.07-4.85s.012-3.584.07-4.85c.062-1.366.34-2.633 1.314-3.608.975-.974 2.242-1.252 3.608-1.314C8.416 2.175 8.796 2.163 12 2.163m0-2.163C8.735 0 8.332.014 7.052.072 5.773.131 4.675.336 3.678 1.334 2.68 2.332 2.476 3.43 2.417 4.71.014 8.332 0 8.735 0 12s.014 3.668.072 4.948c.06 1.28.265 2.378 1.263 3.376.998.997 2.096 1.203 3.376 1.263 1.28.058 1.683.072 4.948.072s3.668-.014 4.948-.072c1.28-.06 2.378-.266 3.376-1.263.997-.998 1.203-2.096 1.263-3.376.058-1.28.072-1.683.072-4.948s-.014-3.668-.072-4.948c-.06-1.28-.266-2.378-1.263-3.376-.998-.997-2.096-1.203-3.376-1.263-1.28-.058-1.683-.072-4.948-.072zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zm0 10.151a3.989 3.989 0 110-7.977 3.989 3.989 0 010 7.977zm6.406-11.845a1.44 1.44 0 110-2.881 1.44 1.44 0 010 2.881z" />
              </svg>
            </a>

            {/* Facebook}
            <a
              href="https://facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="w-16 h-10 fill-current"
                viewBox="0 0 30 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22.675 0H1.325C.594 0 0 .594 0 1.325v21.351C0 23.406.594 24 1.325 24H12.82V14.708h-3.148v-3.62h3.148V8.413c0-3.11 1.897-4.805 4.669-4.805 1.325 0 2.463.099 2.794.143v3.24h-1.918c-1.504 0-1.796.715-1.796 1.763v2.311h3.588l-.467 3.62h-3.12V24h6.116C23.406 24 24 23.406 24 22.675V1.325C24 .594 23.406 0 22.675 0z" />
              </svg>
            </a>

              {/* X}
              <a
                href="https://x.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="w-10 h-10 fill-current"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.0748 15L6.45272 9.60689L1.91833 15H0L5.60164 8.33938L0 0H4.9252L8.33897 5.08297L12.6163 0H14.5346L9.19291 6.35215L15 15H10.0748ZM12.2111 13.4796H10.9196L2.74677 1.52045H4.03844L7.31172 6.30897L7.87775 7.13991L12.2111 13.4796Z" />
                </svg>
              </a>

              {/* Github}
              <a
                href="https://github.com/AAzzam91/LLEAMI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="w-10 h-10 fill-current"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.026 2C7.13295 1.99937 2.96183 5.54799 2.17842 10.3779C1.395 15.2079 4.23061 19.893 8.87302 21.439C9.37302 21.529 9.55202 21.222 9.55202 20.958C9.55202 20.721 9.54402 20.093 9.54102 19.258C6.76602 19.858 6.18002 17.92 6.18002 17.92C5.99733 17.317 5.60459 16.7993 5.07302 16.461C4.17302 15.842 5.14202 15.856 5.14202 15.856C5.78269 15.9438 6.34657 16.3235 6.66902 16.884C6.94195 17.3803 7.40177 17.747 7.94632 17.9026C8.49087 18.0583 9.07503 17.99 9.56902 17.713C9.61544 17.207 9.84055 16.7341 10.204 16.379C7.99002 16.128 5.66202 15.272 5.66202 11.449C5.64973 10.4602 6.01691 9.5043 6.68802 8.778C6.38437 7.91731 6.42013 6.97325 6.78802 6.138C6.78802 6.138 7.62502 5.869 9.53002 7.159C11.1639 6.71101 12.8882 6.71101 14.522 7.159C16.428 5.868 17.264 6.138 17.264 6.138C17.6336 6.97286 17.6694 7.91757 17.364 8.778C18.0376 9.50423 18.4045 10.4626 18.388 11.453C18.388 15.286 16.058 16.128 13.836 16.375C14.3153 16.8651 14.5612 17.5373 14.511 18.221C14.511 19.555 14.499 20.631 14.499 20.958C14.499 21.225 14.677 21.535 15.186 21.437C19.8265 19.8884 22.6591 15.203 21.874 10.3743C21.089 5.54565 16.9181 1.99888 12.026 2Z"></path>
                </svg>
              </a>

            </div>
          </div> */}