//NavBarButton.tsx

import React from "react";

const NavbarButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button                                             
      className="navbar-button bg-gradient-to-r from-[#f6cb0e] to-[#f2d8a0] text-black py-2 px-4 rounded-md font uppercase transition-all duration-300 border border-white shadow-md hover:shadow-lg hover:from-[#f2d8a0] hover:to-[#f6cb0e]"
      onClick={onClick}
    >
      Get Started
    </button>
  );
};

export default NavbarButton;
