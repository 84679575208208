//IdeaButton.tsx

import React from "react";

const IdeaButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button
      className=" idea-button bg-gradient-to-r from-[#f6cb0e] to-[#f2d8a0] text-black py-2 px-4 rounded-md inline-flex items-center gap-2 font-bold uppercase transition-all duration-300 border border-white shadow-md hover:shadow-lg hover:from-[#f2d8a0] hover:to-[#f6cb0e]"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="idea-button__icon"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <g className="idea-button__fill">
          <path d="M12 2a7 7 0 00-7 7c0 2.3 1 4.3 2.8 5.6 1.2 1 1.2 2.1 1.2 2.1h6c0-1 0-1.2 1.2-2.1 1.8-1.4 2.8-3.4 2.8-5.6a7 7 0 00-7-7z" />
        </g>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 18h4v2h-4v-2zM10 22h4v2h-4v-2z"
        />
      </svg>
      <span className="idea-button__label">I Have an Idea</span>
    </button>
  );
};

export default IdeaButton;
