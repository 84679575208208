//YesNoPrompt.tsx

import React from "react";

interface YesNoPromptProps {
  question: string;
  onYes: () => void;
  onNo: () => void;
}

const YesNoPrompt: React.FC<YesNoPromptProps> = ({ question, onYes, onNo }) => {
  return (
    <div className="fixed min-h-screen inset-0 bg-black bg-opacity-60  overflow-auto z-50 ">
      <div className="flex justify-center items-center min-h-full ">
        <div className=" !bg-primary !p-12 rounded-lg shadow-xl border border-yellow-500 w-[500px] max-w-full">
          <p className="text-white mb-8 text-[20px]">{question}</p>
          <div className=" flex flex-col gap-4">
            <button className="primary-btn" onClick={onYes}>
              Yes
            </button>
            <button className="primary-btn" onClick={onNo}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YesNoPrompt;
