//Common.tsx
import React from "react";

export type Plan = {
  name: string;
  price: string;
  priceId: string;
  features: { name: string; included: boolean }[];
};

export const plans: Plan[] = [
  {
    name: "Plan 1",
    price: "€1.00",
    priceId: "price_1QONj6GHcgeXR6iNZskgHl2L",
    features: [
      { name: "Lean Startup Report (DOCX)", included: true },
      { name: "+ Lean Startup Report (PDF)", included: true },
      { name: "+ Lean Canvas HTML (adjustable)", included: false },
      { name: "+ Business Model Canvas HTML (adjustable)", included: false },
    ],
  },
  {
    name: "Plan 2",
    price: "€2.00",
    priceId: "price_1QONk8GHcgeXR6iNea2ftZ7T",
    features: [
      { name: "Lean Startup Report (DOCX)", included: true },
      { name: "+ Lean Startup Report (PDF)", included: true },
      { name: "+ Lean Canvas HTML (adjustable)", included: true },
      { name: "+ Business Model Canvas HTML (adjustable)", included: true },
    ],
  },
];
