//Loading.tsx

import Title from "./Title";

export default function Loading() {
  return (
    <div className="space-x-2">
      <div className="loader-dots">
        <div className="dot bg-yellow-500"></div>
        <div className="dot bg-yellow-500"></div>
        <div className="dot bg-yellow-500"></div>
      </div>
    </div>
  );
}
