// useAnalytics.ts

import { useEffect } from "react";
import Cookies from "js-cookie";

declare global {
  interface Window {
    dataLayer: any[];
    gtag: Gtag;
  }
}

type Gtag = (...args: any[]) => void;

const useAnalytics = () => {
  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (cookieConsent) {
      const preferences = JSON.parse(cookieConsent);
      if (preferences.analytics) {
        // Load Google Analytics
        if (typeof window.gtag !== "function") {
          const script = document.createElement("script");
          script.src = `https://www.googletagmanager.com/gtag/js?id=YOUR_TRACKING_ID`;
          script.async = true;
          document.body.appendChild(script);

          window.dataLayer = window.dataLayer || [];
          window.gtag = function (...args: any[]) {
            window.dataLayer.push(args);
          };

          window.gtag("js", new Date());
          window.gtag("config", "YOUR_TRACKING_ID");
        }
      }
    }
  }, []);
};

export default useAnalytics;
