//Success.tsx

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { Plan } from "./Common";
import { plans } from "./Common";
import Loading from "./loading";
import Title from "./Title";

interface PricingProps {
  setSelectedPlan: (plan: Plan | null) => void;
  setIsPaymentComplete: (status: boolean) => void;
  changeStep: (nextStep: number) => void;
  setPricingStep: (nextStep: number) => void;
}

const Success: React.FC<PricingProps> = ({
  setSelectedPlan,
  setIsPaymentComplete,
  changeStep,
  setPricingStep,
}) => {
  const [isloading, setIsloading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const validatePayment = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get("token");
      const tokenFromSession = sessionStorage.getItem("session_token");
      const planPriceIdFromSession = sessionStorage.getItem("price_id");
      const pricingStep = sessionStorage.getItem("pricing_step") || 0;
      const sessionPlan =
        plans.find((pla) => pla?.priceId === planPriceIdFromSession) || null;

      console.log("pricingStep", pricingStep);
      console.log("sessionPlan", sessionPlan);
      console.log("planPriceIdFromSession", planPriceIdFromSession);

      if (sessionPlan) {
        sessionStorage.setItem("selected_plan", JSON.stringify(sessionPlan));
      }

      if (tokenFromUrl && tokenFromUrl === tokenFromSession) {
        sessionStorage.removeItem("session_token");
        sessionStorage.removeItem("price_id");
        sessionStorage.removeItem("pricing_step");

        setSelectedPlan(sessionPlan);
        setIsPaymentComplete(true);
        if (Number(pricingStep) > 0) {
          changeStep(Number(pricingStep));
          setPricingStep(0);
        }

        setTimeout(() => navigate("/"), 2000);
      } else {
        sessionStorage.removeItem("session_token");
        sessionStorage.removeItem("price_id");
        sessionStorage.removeItem("pricing_step");
        setIsloading(false);
      }
    };

    validatePayment();
  }, [
    setSelectedPlan,
    setIsPaymentComplete,
    changeStep,
    setPricingStep,
    navigate,
  ]);

  if (isloading) {
    return (
      <div className=" min-h-screen h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="min-h-screen h-screen flex justify-center items-center p-4">
      <div>
        <div className=" text-center mb-3">
        <Title height="50px" width="auto" /> {/* Smaller logo for Navbar */}
        </div>
        <h1 className="text-3xl font-bold text-center text-secondary">
          Payment Successful!
        </h1>
        <p className="mt-4 text-center">
          Thank you for your payment. Redirecting to the homepage...
        </p>
      </div>
    </div>
  );
};

export default Success;
