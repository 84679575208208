// // src/index.tsx

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { PUBLISHABLE_KEY } from './config'; // Import the validated Publishable Key
// import { ClerkProvider } from '@clerk/clerk-react'


// // Log the PUBLISHABLE_KEY if needed for debugging (remove in production)
// console.log('Publishable Key:', PUBLISHABLE_KEY);

// const rootElement = document.getElementById('root');

// if (rootElement) {
//   const root = ReactDOM.createRoot(rootElement);
//   root.render(
//     <React.StrictMode>
//      <ClerkProvider publishableKey={PUBLISHABLE_KEY as string} afterSignOutUrl="/">

//       <App />
//       </ClerkProvider>
//     </React.StrictMode>
//   );
// } else {
//   console.error('Failed to find the root element.');
// }




import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PUBLISHABLE_KEY } from './config';
import { ClerkProvider } from '@clerk/clerk-react';
import LogoSVG from './logos_lleami/Svg File/4n.svg';

///console.log('Publishable Key:', PUBLISHABLE_KEY);
//console.log('Using Clerk Publishable Key:', process.env.REACT_APP_CLERK_PUBLISHABLE_KEY);
//console.log('All environment variables:', process.env); // Log all env variables

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ClerkProvider
        publishableKey={PUBLISHABLE_KEY as string}
        afterSignOutUrl="/"
        appearance={{
          variables: {
            // CardQuiz Exact Variables
            colorPrimary: '#f6cb0e', // Yellow gradient start
            colorText: '#ffffff', // White text
            colorBackground: '#06060e', // Dark background
            colorInputBackground: '#1a1a1a', // Input field background
            colorInputText: '#ffffff', // Input field text color
            colorNeutral: '#3a3a3a', // Neutral shades for borders and hover
            borderRadius: '0.375rem', // Matches CardQuiz rounded corners
            fontFamily: 'Montserrat, sans-serif', // Consistent font-family
            fontSize: '1rem', // Text size for better readability
          },
          layout: {
            logoImageUrl: LogoSVG, // Optional: Add your logo
            logoPlacement: 'inside', // Align logo inside the modal
            socialButtonsPlacement: 'bottom', // Place social buttons at the bottom
          },
          elements: {
            // Customize component structure
            card: 'bg-[#06060e] rounded-lg border border-yellow-500 shadow-xl !p-12', // Matches CardQuiz modal
            cardHeader: 'text-center', // Center the modal header
            cardTitle: 'text-white text-xl font-bold', // Bold title styling
            cardSubtitle: 'text-gray-400', // Subtitle text
            formButtonPrimary: 'primary-btn', // Use CardQuiz button style
            formFieldInput: 'input', // Match CardQuiz input field styles
            footerActionLink: 'text-secondary hover:text-yellow-500', // Footer links
          },
        }}
      >
        <App />
      </ClerkProvider>
    </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element.');
}
