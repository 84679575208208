//AnimatedLine.tsx
import React, { useEffect, useState } from "react";

const AnimatedLine: React.FC = () => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    const generateRandomStyle = () => {
      const width = Math.random() * 2 + 1;
      const length = Math.random() * 40;
      const startX = Math.random() * 80;
      const startY = Math.random() * 80;
      const duration = Math.random() * 3 + 2;
      const opacity = Math.random() * 0.5 + 0.3;
      const color = `rgba(246, 203, 14, ${opacity})`;

      const shapes = ["linear", "curve", "zigzag", "circle"];
      const animationShape = shapes[Math.floor(Math.random() * shapes.length)];

      return {
        width: `${width}px`,
        height: `${length}px`,
        backgroundColor: color,
        animation: `${animationShape} ${duration}s linear infinite`,
        position: "absolute",
        top: `${startY}%`,
        left: `${startX}%`,
      };
    };

    setStyle(generateRandomStyle());
  }, []);

  return <div className="animated-line" style={style}></div>;
};

export default AnimatedLine;
