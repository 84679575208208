//DataHndlingPopup.tsx

import React from "react";

interface DataHandlingPopupProps {
  isVisible: boolean;
  onAgree: () => void;
  onDisagree: () => void;
  //onClose: () => void;
}


const DataHandlingPopup: React.FC<DataHandlingPopupProps> = ({
  isVisible,
  onAgree,
  onDisagree,
  //onClose
}) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 z-50 flex justify-center items-center">
      <div className="bg-primary p-8 rounded-lg shadow-lg border border-yellow-500 w-[500px] max-w-full">
        <h2 className="text-white text-xl font-bold mb-6 text-center">
          Data Handling
        </h2>

        {/* What we do NOT save */}
        <div className="mb-6">
          <h3 className="text-yellow-500 text-lg font-semibold mb-2">
            What LLEAMI does <u>NOT</u> save:
          </h3>
          <ul className="list-disc list-inside text-white text-sm">
            <li>Your idea</li>
            <li>All generated files related to the idea</li>
          </ul>
        </div>

        {/* What we do save */}
        <div className="mb-10">
          <h3 className="text-yellow-500 text-lg font-semibold mb-2">
            What LLEAMI does save:
          </h3>
          <ul className="list-disc list-inside text-white text-sm">
            <li>Email</li>
            <li>Chosen language</li>
            <li>Selected region</li>
            <li>Selected plan</li>
            <li>Generation execution time</li>
          </ul>
        </div>

        {/* Buttons */}
        <div className="flex justify-center space-x-4">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition"
            onClick={onAgree}
          >
            I Agree
          </button>
          <button
            className="bg-gray-700 text-white px-4 py-2 rounded-lg shadow hover:bg-gray-800 transition"
            onClick={onDisagree}
          >
            I Do Not Agree
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataHandlingPopup;
