//Form.tsx

import React, { ChangeEvent, useState } from "react";

type FormProps = {
  apiBaseUrl: string;
};

const Form: React.FC<FormProps> = ({ apiBaseUrl }) => {
  const professions = [
    "User",
    "Founder/Entrepreneur",
    "Engineer",
    "Researcher",
    "Innovation Specialist",
    "Student",
    "Other (fill in box)",
  ];

  const [name, setName] = useState("");
  const [profession, setProfession] = useState(professions[0]);
  const [otherProfession, setOtherProfession] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    if (name === "name") setName(value);
    else if (name === "profession") setProfession(value);
    else if (name === "otherProfession") setOtherProfession(value);
    else if (name === "company") setCompany(value);
    else if (name === "email") setEmail(value);
    else if (name === "message") setMessage(value);
  };

  // Function to validate email
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSendClick = async () => {
    setFeedbackMessage(null);
    setError(false);

    if (!name || !email || !message) {
      setFeedbackMessage("Please fill in all required fields.");
      setError(true);
      return;
    }

    if (!isValidEmail(email)) {
      setFeedbackMessage("Please enter a valid email address.");
      setError(true);
      return;
    }

    const formData = {
      name,
      profession:
        profession === "Other (fill in box)" ? otherProfession : profession,
      company,
      email,
      message,
    };

    try {
      const response = await fetch(`${apiBaseUrl}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFeedbackMessage("Your message has been sent successfully!");
        setName("");
        setProfession(professions[0]);
        setOtherProfession("");
        setCompany("");
        setEmail("");
        setMessage("");
      } else {
        setFeedbackMessage("Failed to send message. Please try again later.");
        setError(true);
      }
    } catch (err) {
      console.error(err);
      setFeedbackMessage("An error occurred. Please try again later.");
      setError(true);
    }
  };

  return (
    <div>
      {/* Feedback message */}
      {feedbackMessage && (
        <p
          className={`mb-4 text-sm ${
            error ? "text-red-500" : "text-green-500"
          }`}
        >
          {feedbackMessage}
        </p>
      )}

      <div className="grid gap-4 mb-4 text-white">
        {/* Name Field */}
        <div>
          <label className="block mb-1">Your Full Name *</label>
          <input
            type="text"
            name="name"
            className="w-full p-2 bg-[#06060E] border border-gray-500 rounded-md text-white"
            value={name}
            onChange={handleChange}
            required
          />
        </div>

        {/* Profession Field */}
        <div>
          <label className="block mb-1">Profession</label>
          <select
            className="w-full p-2 bg-[#06060E] border border-gray-500 rounded-md text-white"
            name="profession"
            value={profession}
            onChange={handleChange}
          >
            {professions.map((prof) => (
              <option value={prof} key={prof}>
                {prof}
              </option>
            ))}
          </select>
          {profession === "Other (fill in box)" && (
            <input
              type="text"
              name="otherProfession"
              className="w-full p-2 bg-[#06060E] border border-gray-500 rounded-md text-white"
              value={otherProfession}
              placeholder="Enter profession"
              onChange={handleChange}
            />
          )}
        </div>

        {/* Company Field */}
        <div>
          <label className="block mb-1">Company</label>
          <input
            type="text"
            name="company"
            className="w-full p-2 bg-[#06060E] border border-gray-500 rounded-md text-white"
            value={company}
            onChange={handleChange}
          />
        </div>

        {/* Email Field */}
        <div>
          <label className="block mb-1">Email *</label>
          <input
            type="email"
            name="email"
            className="w-full p-2 bg-[#06060E] border border-gray-500 rounded-md text-white"
            value={email}
            onChange={handleChange}
            required
          />
        </div>

        {/* Message Field */}
        <div>
          <label className="block mb-1">Message *</label>
          <textarea
            rows={4}
            name="message"
            className="w-full p-2 bg-[#06060E] border border-gray-500 rounded-md text-white"
            value={message}
            placeholder="Write your message here..."
            onChange={handleChange}
            required
          ></textarea>
        </div>
      </div>

      {/* Send Message Button */}
      <button className="primary-btn !w-full" onClick={handleSendClick}>
        Send Message
      </button>
    </div>
  );
};

export default Form;
