// // src/config.ts
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:80";
// const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL || "ws://localhost:80/ws/updates";

// export { API_BASE_URL, WS_BASE_URL };


// src/config.ts
// const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "https://lleami-app.azurewebsites.net";
// const WS_BASE_URL =
//   process.env.REACT_APP_WS_BASE_URL || "wss://lleami-app.azurewebsites.net/ws/updates";

// export { API_BASE_URL, WS_BASE_URL };


// // src/config.ts
// const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "https://lleami-app--2bzfbaf.delightfulsky-85cc884d.northeurope.azurecontainerapps.io";
// const WS_BASE_URL =
//   process.env.REACT_APP_WS_BASE_URL || "wss://lleami-app--2bzfbaf.delightfulsky-85cc884d.northeurope.azurecontainerapps.io/ws/updates";

// export { API_BASE_URL, WS_BASE_URL };








// src/config.ts

// // Determine if the app is running in development mode
// const isDevelopment = process.env.NODE_ENV === 'development';

// const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL ||
//   (isDevelopment
//     ? "http://localhost:8000"
//     : "https://lleami-app--2bzfbaf.delightfulsky-85cc884d.northeurope.azurecontainerapps.io");

// const WS_BASE_URL =
//   process.env.REACT_APP_WS_BASE_URL ||
//   (isDevelopment
//     ? "ws://localhost:8000/ws/updates"
//     : "wss://lleami-app--2bzfbaf.delightfulsky-85cc884d.northeurope.azurecontainerapps.io/ws/updates");

// export { API_BASE_URL, WS_BASE_URL };


// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';
// const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL || 'ws://localhost:8000/ws/updates';
// const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || 'pk_test_am9pbnQtc3RpbmtidWctNjYuY2xlcmsuYWNjb3VudHMuZGV2JA';



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://lleami-app--new.delightfulsky-85cc884d.northeurope.azurecontainerapps.io';
const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL || 'wss://lleami-app--new.delightfulsky-85cc884d.northeurope.azurecontainerapps.io/ws/updates';
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || 'pk_live_Y2xlcmsubGxlYW1pLmNvbSQ';



if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key. Please ensure REACT_APP_CLERK_PUBLISHABLE_KEY is set in your environment.");
}

export { API_BASE_URL, WS_BASE_URL, PUBLISHABLE_KEY };