//ConversationFlow.tsx

import React, { useState, useEffect } from "react";
import CardQuiz from "./CardQuiz";
import IdeaButton from "./IdeaButton";
import DataHandlingPopup from "./DataHandlingPopup";
import { useUser, useClerk } from "@clerk/clerk-react";
import CustomSignInModal from "./CustomSignInModal";
import { Plan } from "./Common";
import ExampleModal from "./ExampleModal";

interface ConversationFlowProps {
  conversationStep: number;
  changeStep: (nextStep: number) => void;

  selectedLanguage: string;
  setSelectedLanguage: (language: string) => void;

  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;

  selectedRegion: string;
  setSelectedRegion: (region: string) => void;

  setAgreementStatus: (status: boolean) => void;

  setShowInputArea: (show: boolean) => void;

  openExampleModal: (type: "pdf" | "html") => void;

  goToPreviousStep: () => void;

  isPaymentComplete: boolean;
  setIsPaymentComplete: (status: boolean) => void;

  animateClass: string;

  selectedPlan: Plan | null;
  setSelectedPlan: (plan: Plan | null) => void;
  setPricingStep: (nextStep: number) => void;
  
  pricingStep: number;

  // New handlers for user preferences
  handleLanguageSelection: (language: string) => void;
  handleRegionSelection: (region: string) => void;
  handleAgreement: (status: boolean) => void;
  handlePlanSelection: (plan: Plan | null) => void;
  handleLoginAndProceed: () => void;
  sendPreferencesToBackend: () => Promise<void>;

  userPreferences: {
    email: string;
    language: string;
    region: string;
    agreement: boolean;
    plan: Plan | null;
  };
  setUserPreferences: React.Dispatch<
    React.SetStateAction<{
      email: string;
      language: string;
      region: string;
      agreement: boolean;
      plan: Plan | null;
    }>
  >;
}

const featureMapping: { [key: string]: string } = {
  "Lean Startup Report (DOCX)": "Lean Startup Report (DOCX)",
  "+ Lean Startup Report (PDF)": "+ Lean Startup Report (PDF)",
  "+ Business Model Canvas HTML (adjustable)":
    "+ Business Model Canvas HTML (adjustable)",
  "+ Lean Canvas HTML (adjustable)": "+ Lean Canvas HTML (adjustable)",
};

const ConversationFlow: React.FC<ConversationFlowProps> = ({
  conversationStep,
  changeStep,
  selectedLanguage,
  setSelectedLanguage,
  selectedOptions,
  setSelectedOptions,
  selectedRegion,
  setSelectedRegion,
  setAgreementStatus, 
  setShowInputArea,
  openExampleModal,
  goToPreviousStep,
  isPaymentComplete,
  setIsPaymentComplete,
  animateClass,
  setPricingStep,
  pricingStep,
  selectedPlan,
  userPreferences,
  setUserPreferences,
  handleLanguageSelection,
  handleRegionSelection,
  handleAgreement,
  handlePlanSelection,
  handleLoginAndProceed,
  sendPreferencesToBackend,
  
}) => {

    // Inside your component:
  const { user } = useUser();  // Get user info from Clerk
  //const { openSignIn } = useClerk(); // Clerk's function to open the login modal
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  //const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [showDataPopup, setShowDataPopup] = useState(false); // State for the popup

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setSelectedOptions((prev) =>
      checked ? [...prev, value] : prev.filter((option) => option !== value)
  );
};

  useEffect(() => {
    if (conversationStep === 5 && selectedPlan) {
      const planFeatures = selectedPlan.features
        .filter((feature) => feature.included)
        .map((feature) => feature.name)
        //.filter(Boolean);

      // const updatedOptions = selectedOptions.filter((option) =>
      //   planFeatures.includes(option)
      // );
      
      setSelectedOptions((prev) => {
        const updatedOptions = Array.from(
          new Set([
            ...prev.filter((option) => planFeatures.includes(option)), 
            ...planFeatures
          ])
        );
        return updatedOptions;
      });
    }
  }, [conversationStep, selectedPlan]);



  return (
    <>
    {/* Custom Clerk Sign-In Modal */}
    <CustomSignInModal
      isOpen={isSignInOpen}
      onClose={() => setIsSignInOpen(false)}
    />

    <div>
      {conversationStep === 1 && (
        <div className="message">
          <IdeaButton onClick={() => changeStep(2)} />
        </div>
      )}

      {conversationStep === 2 && (
        <CardQuiz
          setPricingStep={setPricingStep}
          changeStep={changeStep} 
          questionText="Do you want to enter your awesome idea?"
          answers={[
            { text: "Yes", onClick: handleLoginAndProceed }, // Open login modal when "Yes" is clicked
            { text: "No", onClick: () => changeStep(1) },
            {
              text: "See example report (PDF)",
              onClick: () => openExampleModal("pdf"), // Open modal with PDF
              },
            {
              text: "See example Business model canvas (HTML)",
              onClick: () => openExampleModal("html"), // Open modal with HTML
            },
            //{ text: "Back", onClick: goToPreviousStep },
          ]}
          animateClass={animateClass}
        />
      )}

      {conversationStep === 3 && (
        <CardQuiz
          setPricingStep={setPricingStep}
          changeStep={changeStep}
          questionText="Great! Choose language:"
          answers={[
            {
              text: (
                <div>
                <select
                  //value={selectedLanguage}
                  value={userPreferences.language || "English"}
                  onChange={(e) => {
                    //setSelectedLanguage(e.target.value)
                    handleLanguageSelection(e.target.value); // Update preferences
                  }}
                  className="select w-full"
                >
                  <option value="" disabled>Select Language</option>
                  <option value="English">English</option>
                  <option value="Dutch">Dutch</option>
                  <option value="German">German</option>
                  <option value="French">French</option>
                </select>
                 <p className="text-xs italic text-gray-100 mt-1">
                 <span className="font-bold">Note:</span> Default language is English. Some sections may still appear in English due to common keywords.
               </p>
               </div>
              ),
            },
            { text: "Next", onClick: () => changeStep(4) },
            { text: "Back", onClick: goToPreviousStep },
          ]}
          animateClass={animateClass}
        />
        )}

      {conversationStep === 4 && (
        <CardQuiz
          setPricingStep={setPricingStep}
          changeStep={() => setShowDataPopup(true)} // Show the popup after selection
          //changeStep={changeStep}
          questionText="Please choose the region for your idea:"
          answers={[
            {
              text: (
                <div className=" w-full">
                  <select
                    //value={selectedRegion}
                    value={userPreferences.region}
                    onChange={(e) => {
                      //setSelectedRegion(e.target.value);
                      handleRegionSelection(e.target.value); // Update preferences
                    }}
                    className="select w-full max-w-full "
                  >
                    <option value="" disabled>Select Region</option>
                    <option value="North America">North America</option>
                    <option value="Latin America">Latin America</option>
                    <option value="Europe">Europe (UK, non-EU European countries)</option>
                    <option value="Middle East">Middle East</option>
                    <option value="Africa">Africa</option>
                    <option value="Asia">Asia</option>
                    <option value="Everywhere in the world">Everywhere in the world</option>
                  </select>
                </div>
              ),
            },
            {
              text: (
                <button
                  className={`${
                    //selectedRegion
                    userPreferences.region
                      ? "primary-btn"
                      : "primary-btn-disable opacity-50 cursor-not-allowed"
                  }`}
                  onClick={() => setShowDataPopup(true)} // Open the popup
                  //   {
                  //   if (!isPaymentComplete) {
                  //     const pricingSection =
                  //       document.querySelector("#pricing-section");
                  //     if (pricingSection) {
                  //       pricingSection.scrollIntoView({ behavior: "smooth" });
                  //     }
                  //     changeStep(1);
                  //     setPricingStep(5);
                  //   } else {
                  //     changeStep(5);
                  //   }
                  // }}
                  //disabled={!selectedRegion}
                  disabled={!userPreferences.region}
                >
                  Next
                </button>
              ),
            },
            { text: "Back", onClick: goToPreviousStep },
          ]}
          animateClass={animateClass}
        />
        )}


      {conversationStep === 5 && selectedPlan && (
        <CardQuiz
          changeStep={changeStep}
          setPricingStep={setPricingStep}
          questionText="Thank you! Click what to generate:"
          answers={[
            // Filter features included in the selected plan and map them to valid answers
            ...selectedPlan.features
              .filter((feature) => feature.included) // Ensure only included features are processed
              .map((feature, index) => ({
                text: (
                  <label
                    key={feature.name}
                    className="flex items-center space-x-3"
                  >
                    <input
                      type="checkbox"
                      value={feature.name}
                      checked={selectedOptions.includes(feature.name)}
                      disabled={index === 0}
                      onChange={handleOptionChange}
                      className="form-checkbox h-5 w-5 text-secondary rounded focus:ring-secondary"
                    />
                    <span className="text-white">{feature.name}</span>
                  </label>
                ),
              })),
            // Add a conditional message if payment is incomplete
            ...(isPaymentComplete
              ? []
              : [
                  {
                    text: (
                      <p className="text-sm text-red-500">
                        Please complete payment to proceed{" "}
                        <span className="text-blue-600 hover:underline">
                          <a
                            href="#pricing-section"
                            onClick={() => {
                              changeStep(1);
                              setPricingStep(5);
                            }}
                          >
                            Go to pricing
                          </a>
                        </span>
                      </p>
                    ),
                  },
                ]),
            // Proceed button for completed payment
            {
              text: (
                <button
                  className={`${
                    isPaymentComplete
                      ? "primary-btn"
                      : "primary-btn-disable opacity-50 cursor-not-allowed"
                  }`}
                  onClick={() => {
                    console.log(userPreferences); // Add this to inspect values
                    if (
                      userPreferences.email &&
                      userPreferences.language &&
                      userPreferences.region &&
                      userPreferences.agreement &&
                      userPreferences.plan
                    ) {
                      sendPreferencesToBackend(); // Send preferences to the backend
                      setShowInputArea(true);
                      handlePlanSelection(selectedPlan); // Update preferences
                  } else {
                    alert("Please fill out all preferences before proceeding.");
                  }
                  }}
                  disabled={!isPaymentComplete}
                >
                  Proceed to Input Your Idea
                </button>
              ),
              //onClick: () => setShowInputArea(true),
            },
            // Back button
            { text: "Back", onClick: goToPreviousStep },
          ]}
          animateClass={animateClass}
        />
      )}

    </div>
    <DataHandlingPopup
      isVisible={showDataPopup}
      onAgree={() => {
        //setAgreementStatus("I Agree");
        handleAgreement(true); // Update preferences
        setShowDataPopup(false);
        if (!isPaymentComplete) {
          const pricingSection =
            document.querySelector("#pricing-section");
          if (pricingSection) {
            pricingSection.scrollIntoView({ behavior: "smooth" });
          }
          changeStep(1);
          setPricingStep(5);
        } else {
          changeStep(5);
        }
      }}
      onDisagree={() => {
        //setAgreementStatus("I Do Not Agree");
        handleAgreement(false); // Update preferences
        setShowDataPopup(false)
      }} // Close popup
      />
    </>
);
};

export default ConversationFlow;