//TypeAboutUs.tsx

import React, { useState, useEffect } from "react";

const TypingAboutUs: React.FC = () => {
  const [visibleLetters, setVisibleLetters] = useState(0);
  const [isReversing, setIsReversing] = useState(false);

  const text = "ABOUT US";
  const forwardSpeed = 100;
  const backwardSpeed = 50;
  const delayBetweenCycles = 1500;

  useEffect(() => {
    const currentSpeed = isReversing ? backwardSpeed : forwardSpeed;

    const timer = setTimeout(() => {
      if (!isReversing && visibleLetters < text.length) {
        setVisibleLetters(visibleLetters + 1);
      } else if (isReversing && visibleLetters > 0) {
        setVisibleLetters(visibleLetters - 1);
      } else {
        setTimeout(() => setIsReversing(!isReversing), delayBetweenCycles);
      }
    }, currentSpeed);

    return () => clearTimeout(timer);
  }, [visibleLetters, isReversing, text.length]);

  return (
  
    <div className="text-center mb-3">
      <h1 className="text-white text-[20px] lg:text-[26px] !m-0">
        {text.split("").map((char, index) => (
          <span
            key={index}
            className={`transition-opacity duration-400 ease-in-out ${
              index < visibleLetters ? "opacity-100" : "opacity-40"
            }`}
          >
            {char}
          </span>
        ))}
      </h1>
    </div>
  );
};

export default TypingAboutUs;
