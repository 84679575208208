// EmailContact.tsx

import React from "react";
import Form from "./Form"; // Import the Form component
import Title from "./Title";

type EmailContactProps = {
  onClose: () => void;
  apiBaseUrl: string;
};

const EmailContact: React.FC<EmailContactProps> = ({ onClose, apiBaseUrl }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
    <div
      className={`scale-in relative bg-primary p-12 rounded-lg shadow-xl border border-yellow-500 w-[500px] max-w-full`}
    >
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-white hover:text-yellow-500 transition"
          onClick={onClose}
          aria-label="Close"
        >
          &#x2715;
        </button>

         {/* Contact Form */}
         <div>
         <div className="mb-6 text-center">
              <Title height="35px" width="auto" /> {/* Customize size */}
            </div>
          <p className="text-white text-xl font-bold mb-6">Contact Us</p>
          <Form apiBaseUrl={apiBaseUrl} />
        </div>
      </div>
    </div>
  );
};

export default EmailContact;
