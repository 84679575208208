//Cancel.tsx
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Title from "./Title";

const Cancel: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem("session_token");
    sessionStorage.removeItem("price_id");
    sessionStorage.removeItem("pricing_step");

    const redirectToHome = setTimeout(() => {
      navigate("/");
    }, 2000);

    return () => clearTimeout(redirectToHome);
  }, [navigate]);

  return (
    <div className="min-h-screen h-screen flex justify-center items-center">
      <div className="text-center p-10">
        <div className=" text-center mb-3">
        <Title height="44px" width="auto" /> {/* Smaller logo for Navbar */}
        </div>
        <h1 className="text-3xl font-bold text-center text-secondary">
          Payment Canceled
        </h1>
        <p className="mt-4 text-center">
          No worries, you can try again anytime!
        </p>
        <p className="mt-4 text-center">Redirecting to homepage...</p>
      </div>
    </div>
  );
};

export default Cancel;
