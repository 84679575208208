//Pricing.tsx

import { loadStripe } from "@stripe/stripe-js";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import { Plan, plans } from "./Common";

interface PricingProps {
  selectedPlan: Plan | null;
  setSelectedPlan: (plan: Plan) => void;
  isPaymentComplete: boolean;
  setIsPaymentComplete: (status: boolean) => void;
  changeStep: (nextStep: number) => void;
  conversationStep: number;
  setPricingStep: (nextStep: number) => void;
  pricingStep: number;
}

const Pricing: React.FC<PricingProps> = ({
  selectedPlan,
  setSelectedPlan,
  isPaymentComplete,
  setIsPaymentComplete,
  changeStep,
  conversationStep,
  pricingStep,
  setPricingStep,
}) => {
  // comment for bypass payment
  // const stripePromise = loadStripe(
  //   process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
  // );
  // comment for bypass payment
  // const handleCheckout = async (priceId: string) => {
  //   const stripe = await stripePromise;
  //   if (!stripe) {
  //     console.error("Stripe.js has not loaded properly.");
  //     return;
  //   }

  //   const sessionToken = uuidv4();
  //   sessionStorage.setItem("session_token", sessionToken);
  //   sessionStorage.setItem("price_id", priceId);
  //   sessionStorage.setItem("pricing_step", String(pricingStep));

  //   try {
  //     await stripe.redirectToCheckout({
  //       lineItems: [
  //         {
  //           price: priceId,
  //           quantity: 1,
  //         },
  //       ],
  //       mode: "payment",
  //       successUrl: `${window.location.origin}/success?token=${sessionToken}`,
  //       cancelUrl: `${window.location.origin}/cancel`,
  //     });
  //   } catch (error) {
  //     console.error("Error redirecting to checkout:", error);
  //   }
  // };
  return (
    <div
      className="px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 text-white"
      id="pricing-section"
    >
      <h1 className="text-center text-1xl md:text-4xl font-semibold mb-8">
        Pricing
      </h1>
      <div className="flex flex-wrap gap-4 justify-center items-center">
        {plans.map((plan) => (
          <div
            key={plan.name}
            className={`rounded-2xl border p-6 max-w-[400px] bg-[#0b0f16] cursor-pointer transition-all duration-300 ${
              selectedPlan?.name === plan.name
                ? "border-secondary !bg-secondary !bg-opacity-10"
                : "border-gray-200 bg-transparent hover:shadow-yellow-hover hover:scale-105"
            }`}
          >
            <div className="text-white text-center">
              <h2 className="text-2xl font-medium">
                {plan.name}
                <span className="sr-only"> Plan</span>
              </h2>
              <p className="mt-2 sm:mt-4">
                {/* <strong className="text-3xl font-bold sm:text-4xl text-white">
                  {plan.price}
                </strong> */}
                {/* Add strikethrough price with "Try for free" */}
                <div className="relative inline-block text-white">
                  <span className="text-3xl font-bold sm:text-4xl text-gray-400 line-through">
                    {plan.price}
                  </span>
                  <span className="ml-2 text-lg font-medium text-secondary">
                    Try for free
                  </span>
                </div>
              </p>
              <button
                // Uncomment for adding payment
                onClick={() => {
                  setSelectedPlan(plan);
                  setIsPaymentComplete(true);
                  window.history.replaceState(
                    null,
                    "",
                    window.location.pathname + window.location.search
                  );
                  if (pricingStep > 0) {
                    changeStep(pricingStep);
                    setPricingStep(0);
                  }
                }}

                // comment this onClick for bypass payment
                // onClick={() => handleCheckout(plan.priceId)}
                disabled={selectedPlan?.name === plan.name && isPaymentComplete}
                className={`mt-4 block rounded border px-12 py-3 text-center text-sm font-medium transition-all ${
                  selectedPlan?.name === plan.name && isPaymentComplete
                    ? "primary-btn-disable opacity-50 cursor-not-allowed"
                    : "primary-btn"
                }`}
                
              >
                {selectedPlan?.name === plan.name && isPaymentComplete
                  ? "Payment Complete"
                  : "Select"}
              </button>
            </div>
            <div className="text-white">
              <p className="text-lg font-medium mt-4">What's included:</p>
              <ul className="mt-2 space-y-2">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className={`w-5 h-5 ${
                        feature.included ? "text-secondary" : "text-red-700"
                      }`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d={
                          feature.included
                            ? "M4.5 12.75l6 6 9-13.5"
                            : "M6 18L18 6M6 6l12 12"
                        }
                      />
                    </svg>
                    <span className="text-gray-300">{feature.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
