//InputArea.tsx

import React, { FC } from "react";

type TInputAreaProps = {
  promptValue: string;
  setPromptValue: React.Dispatch<React.SetStateAction<string>>;
  handleIdeaSubmission: () => void;
  handleDisplayResult: () => void;
  disabled?: boolean;
  reset?: () => void;
  placeholder?: string; // Added placeholder prop
};

const InputArea: FC<TInputAreaProps> = ({
  promptValue,
  setPromptValue,
  handleIdeaSubmission,
  handleDisplayResult,
  disabled,
  reset,
  placeholder = "Enter your idea here...", // Updated placeholder text

}) => {
  return (
    <form
      className=" flex  w-full py-2 justify-between rounded-lg  bg-secondary bg-opacity-70 px-6  shadow-lg gap-4"
      onSubmit={(e) => {
        e.preventDefault();
        if (reset) reset();
        handleIdeaSubmission();
        //handleDisplayResult();
      }}
    >
      {/* Lightbulb icon */}
      <div className="flex items-center justify-center ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="idea-form__icon text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
          width={40}
          height={40}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 2a7 7 0 00-7 7c0 2.3 1 4.3 2.8 5.6 1.2 1 1.2 2.1 1.2 2.1h6c0-1 0-1.2 1.2-2.1 1.8-1.4 2.8-3.4 2.8-5.6a7 7 0 00-7-7z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10 18h4v2h-4v-2zM10 22h4v2h-4v-2z"
          />
        </svg>
      </div>

      {/* Input field */}
      <div className="flex-grow">
        <input
          placeholder={placeholder}
          className=" w-full  text-[20px] py-6 placeholder:text-[16px] placeholder:text-black bg-transparent text-black outline-none border-none placeholder-gray-600 "
          disabled={disabled}
          value={promptValue}
          required
          onChange={(e) => setPromptValue(e.target.value)}
        />
      </div>

      {/* Submit button */}
      <button
        disabled={disabled}
        type="submit"
        className="relative primary-btn !w-auto ml-4 px-4 py-2 self-center rounded-lg  text-black transition duration-300 ease-in-out disabled:cursor-not-allowed disabled:opacity-90"
      >
        <span>Submit</span>
      </button>
    </form>
  );
};

export default InputArea;
