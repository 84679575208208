// src/components/ExampleModal.tsx

import React from "react";

interface ExampleModalProps {
  isVisible: boolean;
  onClose: () => void;
  fileType: "pdf" | "html"; // New prop for file type
}


const ExampleModal: React.FC<ExampleModalProps> = ({ 
  isVisible, 
  onClose, 
  fileType}) => {
  if (!isVisible) return null;


  return (
    <div
      className="fixed min-h-screen  inset-0 bg-black bg-opacity-60  overflow-auto z-50 "
      onClick={onClose}
    >
      <div className="flex justify-center items-center min-h-full" 
            style={{ padding: "20px" }}>
        <div
          className=" !bg-primary  rounded-lg shadow-xl border border-yellow-500"
          style={{
            width: fileType === "html" ? "1100px" : "900px", // Adjust width
            maxWidth: "100%",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <button className="modal-close" onClick={onClose}>
            &times;
          </button>
          <div className="modal-body">
          <iframe
              src={
                fileType === "html"
                  ? "https://dashmycvstore.blob.core.windows.net/public-files/The_Business_Model_Canvas.html"
                  : "https://dashmycvstore.blob.core.windows.net/public-files/example_report.pdf"
              }
              title={fileType === "html" ? "Business Model Canvas" : "Example Report"}
              width="100%"
              height="700px"
              style={{
                backgroundColor: fileType === "html" ? "white" : "transparent",
                overflow: "auto",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExampleModal;
