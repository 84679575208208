//TimeLine.tsx

import React from "react";

export default function Timeline() {
  return (
    <div className="lg:p-16 p-4 container mx-auto bg-secondary/5 rounded-lg shadow-lg">
      <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start text-white relative">
        {/* Animated Horizontal Line */}
        <div className="absolute top-1/2 transform -translate-y-1/2 w-full hidden lg:flex">
          <div className="h-1 bg-secondary/30 flex-1 relative overflow-hidden">
            <div className="absolute h-1 bg-secondary animate-glow-line"></div>
          </div>
        </div>

        {/* Step 1 */}
        <div className="flex flex-col items-center text-center lg:w-1/5 relative z-10 mb-8 lg:mb-0">
          <h3 className="text-sm lg:text-md font-semibold text-white mb-2">
            1. <span className="font-bold">Get Started</span>
          </h3>
          <div
            className="w-8 h-8 rounded-full flex items-center justify-center font-semibold text-black bg-secondary animate-glow-circle"
            style={{ animationDelay: "0s" }}
          >
            1
          </div>
          <p className="text-xs text-gray-400 mt-2">
            Start by clicking I Have An Idea to share your awesome idea.
          </p>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center text-center lg:w-1/5 relative z-10 mb-8 lg:mb-0">
          <h3 className="text-sm lg:text-md font-semibold text-white mb-2">
            2. <span className="font-bold">Select Language</span>
          </h3>
          <div
            className="w-8 h-8 rounded-full flex items-center justify-center font-semibold text-black bg-secondary animate-glow-circle"
            style={{ animationDelay: "1s" }}
          >
            2
          </div>
          <p className="text-xs text-gray-400 mt-2">
            Choose the language for your report.
          </p>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center text-center lg:w-1/5 relative z-10 mb-8 lg:mb-0">
          <h3 className="text-sm lg:text-md font-semibold text-white mb-2">
            3. <span className="font-bold">Select Region</span>
          </h3>
          <div
            className="w-8 h-8 rounded-full flex items-center justify-center font-semibold text-black bg-secondary animate-glow-circle"
            style={{ animationDelay: "2s" }}
          >
            3
          </div>
          <p className="text-xs text-gray-400 mt-2">
            Specify the target region for your idea.
          </p>
        </div>

        {/* Step 4 */}
        <div className="flex flex-col items-center text-center lg:w-1/5 relative z-10 mb-8 lg:mb-0">
          <h3 className="text-sm lg:text-md font-semibold text-white mb-2">
            4. <span className="font-bold">Select Plan</span>
          </h3>
          <div
            className="w-8 h-8 rounded-full flex items-center justify-center font-semibold text-black bg-secondary animate-glow-circle"
            style={{ animationDelay: "3s" }}
          >
            4
          </div>
          <p className="text-xs text-gray-400 mt-2">
            Select your plan and choose features.
          </p>
        </div>

        {/* Step 5 */}
        <div className="flex flex-col items-center text-center lg:w-1/5 relative z-10">
          <h3 className="text-sm lg:text-md font-semibold text-white mb-2">
            5. <span className="font-bold">Input an Idea</span>
          </h3>
          <div
            className="w-8 h-8 rounded-full flex items-center justify-center font-semibold text-black bg-secondary animate-glow-circle"
            style={{ animationDelay: "4s" }}
          >
            5
          </div>
          <p className="text-xs text-gray-400 mt-2">
            Proceed to generate your customized report.
          </p>
        </div>
      </div>
    </div>
  );
}
