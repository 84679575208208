//TypingAnimation.tsx

import React, { useState, useEffect } from "react";

const TypingAnimation: React.FC = () => {
  const [visibleLetters, setVisibleLetters] = useState(0);
  const [isReversing, setIsReversing] = useState(false);

  const forwardSpeed = 100;
  const backwardSpeed = 50;
  const delayBetweenCycles = 1500;

  //const preHighlight = "LLEAMI ";
  const postHighlight =
    "From idea to lean startup plan powered by our dynamic AI team";
  const fullTextLength = postHighlight.length;

  useEffect(() => {
    const currentSpeed = isReversing ? backwardSpeed : forwardSpeed;

    const timer = setTimeout(() => {
      if (!isReversing && visibleLetters < fullTextLength) {
        setVisibleLetters(visibleLetters + 1);
      } else if (isReversing && visibleLetters > 0) {
        setVisibleLetters(visibleLetters - 1);
      } else {
        setTimeout(() => setIsReversing(!isReversing), delayBetweenCycles);
      }
    }, currentSpeed);

    return () => clearTimeout(timer);
  }, [visibleLetters, isReversing, fullTextLength]);

  return (
    <div className="overflow-hidden max-w-3xl w-full text-center relative p-6 container !z-50">
      <h1 className="text-white transition-opacity duration-400 ease-in-out">
        {/* <span className="highlight text-secondary font-bold text-[35px] lg:text-[66px]">
          {preHighlight}
        </span> */}
        {postHighlight.split("").map((char, index) => (
          <span
            key={`post-${index}`}
            className={`transition-opacity uppercase duration-400 text-[26px] lg:text-[35px] ease-in-out ${
              index < visibleLetters ? "opacity-100" : "opacity-40"
            }`}
          >
            {char}
          </span>
        ))}
      </h1>
    </div>
  );
};

export default TypingAnimation;
