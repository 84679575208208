// //NavBar.tsx

import React from "react";
import Title from "./Title";
import NavbarButton from "./NavbarButton"; // Import the styled button
import { useUser, useClerk } from "@clerk/clerk-react";

type Plan = {
  name: string;
  price: string;
  features: { name: string; included: boolean }[];
};

interface NavbarProps {
  selectedPlan: Plan | null;
}

const Navbar: React.FC<NavbarProps> = ({ selectedPlan }) => {

  const { user } = useUser(); // Correctly access user
  const { openSignIn, signOut } = useClerk();

  return (
    <div className="relative p-2 flex justify-between items-center lg:p-6 bg-[#06060e] shadow-[0px_0px_20px_rgba(246,203,14,0.6)] border-secondary z-50">
      {/* Left: Title */}
      <Title height="44px" width="auto" /> {/* Smaller logo for Navbar */}

       {/* Right: Authentication and Plan Details */}
       <div className="auth-controls flex flex-col items-end gap-2 mt-2">
        {/* Show Sign In Button if User is Not Logged In */}
        {!user ? (
          <NavbarButton onClick={openSignIn} /> // Sign In using Clerk
        ) : (
          /* Show Sign Out Button if User is Logged In */
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={() => signOut()}
          >
            Sign Out
          </button>
        )}

        {/* Display Selected Plan */}
        {selectedPlan && (
          <div className="text-white md:text-lg font-semibold mt-2 text-right">
            <span style={{ fontSize: "15px" }} className="text-secondary">
              Selected:{" "}
            </span>
            <span style={{ fontSize: "15px" }}>{selectedPlan.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
