//ErrorPopup.tsx

import React from "react";

type ErrorPopupProps = {
  errorMessage: string;
  onClose: () => void;
};

const ErrorPopup: React.FC<ErrorPopupProps> = ({ errorMessage, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className="bg-primary p-12 rounded-lg shadow-xl border border-yellow-500 w-[500px] max-w-full relative">
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-yellow-500 transition"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <div>
          <p className="text-white text-xl font-bold mb-6">{errorMessage}</p>
          <button
            onClick={onClose}
            className="primary-btn !w-auto px-6 py-2 text-center uppercase"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;