// import React from "react";

// const Title: React.FC = () => {
//   return (
//     <div
//       className="font-bold text-3xl md:text-4xl lg:text-5xl leading-none cursor-pointer inline-block"
//       onClick={() => {
//         window.location.reload();
//       }}
//     >
//       <span className="L bg-gradient-to-r from-[#f6cb0e] to-[#0c121e] bg-clip-text text-transparent">
//         L
//       </span>
//       <span className="L bg-gradient-to-r from-[#f6cb0e] to-[#0c121e] bg-clip-text text-transparent">
//         L
//       </span>
//       <span className="E bg-gradient-to-r from-white to-[#0c121e] bg-clip-text text-transparent">
//         E
//       </span>
//       <span className="A bg-gradient-to-r from-white to-[#0c121e] bg-clip-text text-transparent">
//         A
//       </span>
//       <span className="M bg-gradient-to-r from-[#f6cb0e] to-[#0c121e] bg-clip-text text-transparent">
//         M
//       </span>
//       <span className="I bg-gradient-to-r from-white to-[#0c121e] bg-clip-text text-transparent">
//         I
//       </span>
//     </div>
//   );
// };

// export default Title;



// import React from "react";
// import Logo from "../logos_lleami/44.png"; // Ensure the path is correct

// interface TitleProps {
//   height?: string; // Optional height prop
//   width?: string;  // Optional width prop
// }

// const Title: React.FC<TitleProps> = ({ height = "150px", width = "auto" }) => {
//   return (
//     <div
//       className="logo-container"
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         padding: 0,
//         margin: 0,
//       }}
//     >
//       <img
//         src={Logo}
//         alt="Logo"
//         style={{
//           height: height, // Use the height prop
//           width: width,   // Use the width prop
//           display: "block", // Prevent extra inline space
//           margin: 0,       // Ensure no margin
//           padding: 0,
//         }}
//       />
//     </div>
//   );
// };

// export default Title;



//Title.tsx

import React from "react";
import LogoSVG from "../logos_lleami/Svg File/4n.svg"; // Adjust the path as necessary

type TitleProps = {
  height?: string; // Optional prop for height
  width?: string; // Optional prop for width
};

const Title: React.FC<TitleProps> = ({ height = "150px", width = "auto" }) => {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={LogoSVG}
        alt="Logo"
        style={{
          height: height, // Use height prop
          width: width, // Use width prop
          display: "block",
          margin: 0,
        }}
      />
    </div>
  );
};

export default Title;
