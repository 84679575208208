import React, { useState, useEffect, useCallback, useRef  } from "react";
import Footer from "./components/Footer";
import TypingAnimation from "./components/TypingAnimation";
import Hero from "./components/Hero";
import YesNoPrompt from "./components/YesNoPrompt";
import ConversationFlow from "./components/ConversationFlow";
import ExampleModal from "./components/ExampleModal";
// import { useWebSocket } from "./hooks/useWebSocket";
import Navbar from "./components/Navbar";
import VideoSection from "./components/VideoSection";
import Pricing from "./components/Pricing";
import Timeline from "./components/Timeline";
import IdeaButton from "./components/IdeaButton";
import AnimatedLine from "./components/AnimatedLine";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import { API_BASE_URL, WS_BASE_URL } from "./config";
import ErrorPopup from "./components/ErrorPopup"; // Adjust the path as necessary
import CookieConsentBanner from "./components/CookieConsentBanner";
import useAnalytics from "./hooks/useAnalytics";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import { Plan } from "./components/Common";
import { useUser, useClerk } from "@clerk/clerk-react";

const App: React.FC = () => {
  useAnalytics(); // Load analytics scripts based on consent
  const [markdownMessages, setMarkdownMessages] = useState<string[]>([]);
  const [isMarkdownVisible, setIsMarkdownVisible] = useState(false);
  const [hasConfirmedIdea, setHasConfirmedIdea] = useState<boolean>(false);
  const [promptValue, setPromptValue] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [showYesNoButtons, setShowYesNoButtons] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTask, setCurrentTask] = useState<string | null>(null);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [hasResponded, setHasResponded] = useState<boolean>(false);
  const [conversationStep, setConversationStep] = useState<number>(1);
  // const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<string[]>(["Lean startup report",]);
  // const [selectedRegion, setSelectedRegion] = useState<string>("");
  const [agreementStatus, setAgreementStatus] = useState<boolean>(false);
  const [showInputArea, setShowInputArea] = useState<boolean>(false);
  const [showExampleModal, setShowExampleModal] = useState<boolean>(false);
  const [exampleFileType, setExampleFileType] = useState<"pdf" | "html">("pdf");
  const [animateClass, setAnimateClass] = useState<string>("scale-in");
  // const [reportDOCXFilename, setReportDOCXFilename] = useState<string>("");
  // const [reportPDFFilename, setReportPDFFilename] = useState<string>("");
  // const [leanCanvasFilename, setLeanCanvasFilename] = useState<string>("");
  // const [bmCanvasFilename, setBmCanvasFilename] = useState<string>("");
  const [isPaymentComplete, setIsPaymentComplete] = useState<boolean>(() => {
    const savedPlan = sessionStorage.getItem("selected_plan");
    return !!savedPlan;
  });
  // const [selectedPlan, setSelectedPlan] = useState<Plan | null>(() => {
  //   const savedPlan = sessionStorage.getItem("selected_plan");
  //   return savedPlan ? (JSON.parse(savedPlan) as Plan) : null;
  // });
  const [pricingStep, setPricingStep] = useState<number>(0);
  const [errorPopup, setErrorPopup] = useState<string | null>(null);



  const openExampleModal = (type: "pdf" | "html") => {
    setExampleFileType(type); // Set the file type
    setShowExampleModal(true); // Show the modal
  };

  const closeExampleModal = () => {
    setShowExampleModal(false);
  };

  const changeStep = (nextStep: number) => {
    setAnimateClass("scale-out");
    setTimeout(() => {
      setConversationStep(nextStep);
      setAnimateClass("scale-in");
    }, 300);
  };

  const goToPreviousStep = () => {
    setAnimateClass("scale-out");
    setTimeout(() => {
      setConversationStep((prevStep) => {
        switch (prevStep) {
          case 2:
            return 1;
          case 3:
            return 2;
          case 4:
            return 3;
          case 5:
            return 4;
          case 6:
            return 5;
          default:
            return prevStep;
        }
      });
      setAnimateClass("scale-in");
    }, 300);
  };


  // Correctly typed userPreferences state initialization
  const [userPreferences, setUserPreferences] = useState<{
    email: string;
    language: string;
    region: string;
    agreement: boolean;
    plan: Plan | null;
  }>({
    email: "",           
    language: "English",
    region: "",
    agreement: false,
    plan: (() => {
      const savedPlan = sessionStorage.getItem("selected_plan");
      return savedPlan ? (JSON.parse(savedPlan) as Plan) : null;
    })(),         
  });
  

  const { user } = useUser();
  const { openSignIn } = useClerk(); // Clerk's function to open the login modal

  //Update the email in userPreferences using Clerk's useUser
  const handleLoginAndProceed = () => {
    if (user) {
      setUserPreferences((prev) => ({
        ...prev,
        email: user.primaryEmailAddress?.emailAddress || "",
      }));
      changeStep(3); // Proceed to the next step
    } else {
      openSignIn(); // Open the login modal if not logged in
    }
  };

  //When User Selects Language: Update the language in userPreferences:
  const handleLanguageSelection = (lang: string) => {
    setUserPreferences((prev) => ({
      ...prev,
      language: lang,
    }));
  };
  //When User Selects Region: Update the region in userPreferences:
  const handleRegionSelection = (region: string) => {
    setUserPreferences((prev) => ({
      ...prev,
      region: region,
    }));
  };
  //When User Agrees to Data Handling: Update the agreement in userPreferences:
  const handleAgreement = (status: boolean) => {
    setUserPreferences((prev) => ({
      ...prev,
      agreement: status,
    }));
  };
  //When User Selects a Plan: Update the plan in userPreferences:
  const handlePlanSelection = (plan: Plan | null) => {
    if (plan) {
      sessionStorage.setItem("selected_plan", JSON.stringify(plan));
      setUserPreferences((prev) => {
        const updatedPreferences = { ...prev, plan };
        //sessionStorage.setItem("selected_plan", JSON.stringify(plan));  // Save to sessionStorage
        return updatedPreferences;
      });
    } else {
      // If the plan is null (or user deselects it), remove it from sessionStorage
      sessionStorage.removeItem("selected_plan");
  }
};

  useEffect(() => {
    return () => {
        // Explicitly clear the sessionStorage when the component is unmounted (or page is closed)
        sessionStorage.removeItem("selected_plan");
    };
  }, []);

  //When the user reaches the "idea input box," send the collected userPreferences to the backend.
  const sendPreferencesToBackend = async () => {
    try {
      const payload = {
        ...userPreferences,
        plan: userPreferences.plan?.name || "", // Extract only the plan name
      };

      const response = await fetch(`${API_BASE_URL}/user-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Failed to send user preferences");
      }
  
      console.log("Preferences successfully sent!");
    } catch (error) {
      console.error("Error sending preferences:", error);
    }
  };
  //Trigger this function when the user reaches the "idea input box":
  useEffect(() => {
    if (showInputArea && userPreferences.agreement && userPreferences.plan) {
      sendPreferencesToBackend();
    }
  }, [showInputArea, userPreferences]);


  const [originalPromptValue, setOriginalPromptValue] = useState<string>("");

  const handleIdeaSubmission = async () => {
    if (promptValue.trim().length < 30) {
      alert("Your idea must be at least 50 characters long.");
      return;
    }
  
    try {
      // Save original prompt before sending it for correction
      setOriginalPromptValue(promptValue);

      const payload = {
        startup_idea: promptValue,
        language_choice: userPreferences.language || "English",
      };
  
      const response = await fetch(`${API_BASE_URL}/correct-idea`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Failed to process the idea.");
      }
  
      const data = await response.json();
      console.log("Backend Response:", data);
  
      // Check if message exists and update frontend
      if (data.message) {
        setQuestion(data.message);
        setShowYesNoButtons(true);
  
        const correctedIdeaMatch = data.message.match(
          /Corrected Idea: (.*)\nShould I proceed with this idea\?/
        );
  
        if (
          correctedIdeaMatch &&
          !correctedIdeaMatch[1].startsWith("It seems that") &&
          !correctedIdeaMatch[1].startsWith("The text")
        ) {
          setPromptValue(correctedIdeaMatch[1]); // Update corrected idea
        }
      }
    } catch (error) {
      console.error("Error processing idea:", error);
      setErrorPopup("Error processing your idea. Please try again.");
    }
  };
  

  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  // 1. `handleUserResponse`: This handles the user's response (Yes or No).
  const handleUserResponse = (response: string) => {
    setShowYesNoButtons(false);
    setQuestion("");

    if (response.toLowerCase() === "yes") {

       // Prevent establishing a new connection if one already exists and is open
       if (websocket && websocket.readyState === WebSocket.OPEN) {
        console.log("WebSocket already open. No new connection established.");
        return
       }
        try {
            // Send 'yes' to confirm user's decision
            setHasConfirmedIdea(true);
            setLoading(true);
            setIsAnimating(true);
            setCurrentTask(""); // Reset task description
            setIsMarkdownVisible(true); // Show markdown rendering phase

            const socketInstance = connectToWebSocket({
              email: userPreferences.email,
              idea_input: promptValue,
              language_choice: userPreferences.language,
              region_input: userPreferences.region,
              agreement: userPreferences.agreement,
              plan_choice: userPreferences.plan?.name || "",
          });
          setWebsocket(socketInstance); // Save the WebSocket instance for cleanup

        } catch (error) {
            console.error("Error processing response:", error);
        }
      
    } else {
        setPromptValue(originalPromptValue); // Reset the original prompt if canceled
        setHasConfirmedIdea(false);
        setLoading(false); // Stop loading
        setIsMarkdownVisible(false); // Hide markdown messages
    }
};

  const isMarkdownVisibleRef = useRef(isMarkdownVisible);
  useEffect(() => {
    isMarkdownVisibleRef.current = isMarkdownVisible;
  }, [isMarkdownVisible]);


  const connectToWebSocket = (payload: any) => {
    const socket = new WebSocket(WS_BASE_URL);

    socket.onopen = () => {
        console.log("WebSocket connection established");
        // Send 'yes' to indicate user confirmed and ready to proceed
        socket.send("yes");
    };

    socket.onmessage = (event) => {
        const data = event.data;
        //console.log("Backend WebSocket Response:", data);

        // Step 1: Check if backend asks for the JSON input
        if (data.includes("Please send the idea data in JSON format.")) {
            //console.log("Backend is waiting for JSON input...");

            // Step 2: Send the user's selected data as JSON
            const userPreferencesPayload = {
                email: userPreferences.email,
                idea_input: promptValue,
                language_choice: userPreferences.language,
                region_input: userPreferences.region,
                agreement: userPreferences.agreement,
                plan_choice: userPreferences.plan?.name || "",
            };

            // Send the JSON payload to the backend
            socket.send(JSON.stringify(userPreferencesPayload));
            //console.log("Sent user data as JSON:", userPreferencesPayload);

        } else if (isMarkdownVisibleRef.current && (
          data.startsWith("#") || data.includes("**") || data.includes("<") || data.includes("```")
        )) {
          //console.log("Markdown detected, adding to markdownMessages:", data);
          setMarkdownMessages((prevMessages) => [...prevMessages, data]);
          //console.log("Added to markdownMessages:", data);
        
        } else if (/Generated successfully\. Email on the way\.\.\. 🚀/.test(data)) {
          // Handle task completion response from the backend
          console.log("Backend responded with:", data);
          //setShowInputArea(false); // Hide the input box area
          setCurrentTask(data);
          setLoading(false);
          setIsAnimating(false);
          console.log("currentTask set to:", data);
         
        } else if (data.includes("Error")) {
          // Handle error responses from the backend
          console.log("Backend responded with an error:", data);
          setIsAnimating(false);
          setLoading(false);

        } else {
            // Handle other types of messages from the backend
            console.log("Received unexpected message:", data);
            setLoading(false);
            setIsAnimating(false);
            setCurrentTask(data);
        }
    };

    socket.onerror = (error) => {
        console.error("WebSocket Error:", error);
    };

    socket.onclose = () => {
        console.log("WebSocket connection closed");
    };

    return socket;
};


  // 3. `useEffect`: Cleanup WebSocket connection when the component unmounts
  useEffect(() => {
      return () => {
          if (websocket) {
              websocket.close(); // Clean up WebSocket connection
              console.log("WebSocket connection closed on component unmount.");
          }
      };
  }, [websocket]); // Run when WebSocket instance changes


  const handleDisplayResult = async () => {
    setHasResponded(false);
    setCurrentTask("");
    setLoading(false);
    setIsAnimating(false);
    setQuestion("");
    setShowYesNoButtons(false);
    // Reset markdown messages to prevent showing old content
    setMarkdownMessages([]);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      window.history.replaceState(
        null,
        "",
        window.location.pathname + window.location.search
      );
    }
  }, []);




  return (
    <Router>
      <CookieConsentBanner /> {/* Add the consent banner */}
      {errorPopup && (
        <ErrorPopup
          errorMessage={errorPopup}
          onClose={() => setErrorPopup(null)}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <div className="relative  min-h-screen flex flex-col overflow-hidden">
              {Array.from({ length: 40 }).map((_, index) => (
                <AnimatedLine key={index} />
              ))}
              <div className="floating-shapes">
                <div className="shape shape-1"></div>
                <div className="shape shape-2"></div>
                <div className="shape shape-3"></div>
                <div className="shape shape-4"></div>
                <div className="shape shape-5"></div>
              </div>

              <Navbar selectedPlan={userPreferences.plan} />

              <main className=" flex flex-1  flex-col items-center p-4 space-y-4">
                <TypingAnimation />

                {!showInputArea && (
                  <ConversationFlow
                    conversationStep={conversationStep}
                    changeStep={changeStep}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    selectedLanguage={userPreferences.language}
                    setSelectedLanguage={(lang) => {
                      setUserPreferences((prev) => ({
                        ...prev,
                        language: lang,
                      }));
                      handleLanguageSelection(lang); // Update language in userPreferences
                    }}
                    // selectedLanguage={selectedLanguage}
                    // //setSelectedLanguage={setSelectedLanguage}
                    // setSelectedLanguage={(lang) => {
                    //   setSelectedLanguage(lang);
                    //   handleLanguageSelection(lang); // Update language in userPreferences
                    // }}

                    selectedRegion={userPreferences.region}
                    setSelectedRegion={(region) => {
                      setUserPreferences((prev) => ({
                        ...prev,
                        region: region,
                      }));
                      handleRegionSelection(region); // Update region in userPreferences
                    }}
                    // selectedRegion={selectedRegion}
                    // //setSelectedRegion={setSelectedRegion}
                    // setSelectedRegion={(region) => {
                    //   setSelectedRegion(region);
                    //   handleRegionSelection(region); // Update region in userPreferences
                    // }}

                    setAgreementStatus={(status: boolean) => {
                      setUserPreferences((prev) => ({
                        ...prev,
                        agreement: status,
                      }));
                      handleAgreement(status); // Update agreement in userPreferences
                    }}
                    // //setAgreementStatus={setAgreementStatus}
                    // setAgreementStatus={(status: boolean) => {
                    //   setAgreementStatus(status);
                    // //setAgreementStatus(status);
                    //   handleAgreement(status); // Update agreement in userPreferences
                    // }}

                    selectedPlan={userPreferences.plan} // Should be userPreferences.plan instead of selectedPlan
                    setSelectedPlan={(plan) => {
                      if (plan) {
                        setUserPreferences((prev) => ({
                          ...prev,
                          plan: plan,
                        }));
                        handlePlanSelection(plan);
                      }
                    }}
                    // selectedPlan={selectedPlan}
                    // setSelectedPlan={setSelectedPlan}
                  
                    userPreferences={userPreferences}
                    setUserPreferences={setUserPreferences}
                    setShowInputArea={setShowInputArea}
                    openExampleModal={openExampleModal}
                    goToPreviousStep={goToPreviousStep}
                    animateClass={animateClass}
                    isPaymentComplete={isPaymentComplete}
                    setIsPaymentComplete={setIsPaymentComplete}
                    pricingStep={pricingStep}
                    //setPricingStep={setPricingStep}
                    setPricingStep={(step) => {
                      setPricingStep(step);
                      if (step === 5 && userPreferences.plan !== null) {
                        handlePlanSelection(userPreferences.plan); // Only call handlePlanSelection if plan is not null
                      }
                      }}

                    handleLanguageSelection={handleLanguageSelection}
                    handleRegionSelection={handleRegionSelection}
                    handleAgreement={handleAgreement}
                    handlePlanSelection={handlePlanSelection}
                    handleLoginAndProceed={handleLoginAndProceed}
                    sendPreferencesToBackend={sendPreferencesToBackend} 
                  />
                  )}

                {showInputArea && !hasConfirmedIdea && showYesNoButtons && (
                  <YesNoPrompt
                    question={question}
                    onYes={() => handleUserResponse("yes")}
                    onNo={() => handleUserResponse("no")}
                  />
                )}
                <>

                {showInputArea && (
                  <Hero
                    promptValue={promptValue}
                    setPromptValue={setPromptValue}
                    handleIdeaSubmission={handleIdeaSubmission}
                    handleDisplayResult={handleDisplayResult}
                    isLoading={loading}
                    currentTask={currentTask || " "}
                    markdownMessages={markdownMessages}
                    isMarkdownVisible={isMarkdownVisible}
                    userPreferences={userPreferences}
                  />
                )}
                </>

                {!showInputArea && <VideoSection />}
                {!showInputArea && (
                  <>
                    <Timeline />
                    <div className=" flex flex-col w-auto sm:flex-row container justify-center !mt-20 gap-[20px]">
                      <IdeaButton onClick={() => changeStep(2)} />
                      <a
                        href="#video"
                        className="  primary-btn !w-auto text-center "
                      >
                        <button className=" uppercase mt-1">
                          About US
                        </button>
                      </a>
                    </div>
                  </>
                )}
                {!showInputArea && (
                <Pricing
                  selectedPlan={userPreferences.plan}
                  setSelectedPlan={(plan) => {
                    setUserPreferences((prev) => ({
                      ...prev,
                      plan: plan,
                    }));
                    handlePlanSelection(plan); // Update plan
                  }}
                  isPaymentComplete={isPaymentComplete}
                  setIsPaymentComplete={setIsPaymentComplete}
                  changeStep={changeStep}
                  conversationStep={conversationStep}
                  pricingStep={pricingStep}
                  setPricingStep={setPricingStep}
                />
                )}
              </main>

              <Footer apiBaseUrl={API_BASE_URL} />

              {/* Modal for example report */}
              <ExampleModal
                isVisible={showExampleModal}
                onClose={closeExampleModal}
                fileType={exampleFileType}
              />
            </div>
          }
        />
        <Route
          path="/success"
          element={
            <Success
              setSelectedPlan={(plan) => {
                setUserPreferences((prev) => ({
                  ...prev,
                  plan: plan,
                }));
              }}
              setIsPaymentComplete={setIsPaymentComplete}
              changeStep={changeStep}
              setPricingStep={setPricingStep}
            />
          }
        />
        <Route path="/cancel" element={<Cancel />} />
      </Routes>
    </Router>
  );
};

export default App;